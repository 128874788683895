import * as React from "react";

import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  DatePicker,
  Image,
  Input,
  Modal,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import { useDebounce } from "use-debounce";
import AbstractTable from "../../../AbstractTable";
import { GET_LIGHTBOXS_WITH_FILTER } from "../../../../../graphql/lightboxs";
import moment from "moment";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/lib/date-picker";
import {
  GET_INCIDENTS_BY_BOX,
  GET_INCIDENT_TYPES,
} from "../../../../../graphql/incident";
import { Order } from "../../../../../graphql/common";
import { filter } from "lodash";
import { IncidentContext } from "../../../../../context/IncidentContext";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
interface IDetailIncidentsProps {
  lightbox: any;
  isOpen: boolean;
}

const DetailIncidents: React.FunctionComponent<IDetailIncidentsProps> = ({
  lightbox,
  isOpen,
  ...props
}) => {
  const dateFormat = "YYYY-MM-DD HH:mm";
  const defaultFilterRange: [moment.Moment, moment.Moment] = [
    moment().subtract(1, "days"),
    moment(),
  ];
  const [tempFilterRange, setTempFilterRange] =
    React.useState<any>(defaultFilterRange);

  const defaultFilterValue = {
    dateRange: defaultFilterRange,
    typeIds: ["all"],
    status: ["0", "1"],
  };

  const [currentIncidents, setCurrentIncidents] = React.useState<any[]>([]);
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [filterInput, setFilterInput] = React.useState<any>();
  const [filterValue, setFilterValue] = React.useState<any>(defaultFilterValue);
  const [incidentTypes, setIncidentTypes] = React.useState<any>([]);

  const { loading } = useQuery<any, any>(GET_INCIDENT_TYPES, {
    onCompleted(data) {
      setIncidentTypes(data.incidentTypes);
    },
  });

  const { data } = useQuery<any, any>(GET_INCIDENTS_BY_BOX, {
    variables: {
      input: {
        lightboxId: lightbox ? lightbox.id : -1,
        ...filterInput,
      },
    },
    pollInterval: 30000,
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      setDataSource(data.incidentByBox.map((i: any) => dataMapFunction(i)));
      setCurrentIncidents(data.incidentByBox.filter((r: any) => !r.isEnded));
    },
  });

  const resetFilter = () => {
    setFilterValue(defaultFilterValue);
  };

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "index",
      width: 20,
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Lỗi",
      dataIndex: "typeId",
      render(_, { typeId, isEnded }) {
        const type = incidentTypes.find((i: any) => i.id === typeId);
        return <>{type.name}</>;
      },
    },
    {
      title: "Bắt đầu",
      dataIndex: "start",
      render(_, { start }) {
        const result = moment(start).utcOffset(7, false).format(dateFormat);
        return <>{result}</>;
      },
    },
    {
      title: "Kéo dài trong",
      render(_, { start, end }) {
        const compared = end ? end : new Date();
        const diff = moment(compared).diff(start, "minutes");
        const minute = diff % 60;
        const hour = Math.floor(diff / 60) % 24;
        const day = Math.floor(diff / 1440);
        let result = "";
        if (day > 0) {
          result = `${day} ngày ${hour} giờ`;
        } else {
          if (hour > 0) result = `${hour} giờ ${minute} phút`;
          else if (minute > 0) result = `${minute} phút`;
          else result = `${moment(compared).diff(start, "seconds")} giây`;
        }
        return <>{result}</>;
      },
    },
    {
      title: "Kết thúc",
      dataIndex: "end",
      render(_, { end }) {
        if (!end) return <>N/A</>;
        const result = moment(end).utcOffset(7, false).format(dateFormat);
        return <>{result}</>;
      },
    },
  ];
  const dataMapFunction = (i: any) => ({
    key: i.id,
    typeId: i.typeId,
    start: i.time,
    end: i.endTime,
    isEnded: i.isEnded,
  });

  React.useEffect(() => {
    const { dateRange, typeIds, status } = filterValue;
    setFilterInput({
      from: dateRange[0].toDate(),
      to: dateRange[1].toDate(),
      isEnded: status.length === 1 ? status[0] === "1" : undefined,
      typeIds: typeIds[typeIds.length - 1] === "all" ? undefined : typeIds,
    });
  }, [filterValue]);

  return (
    <div className={`flex flex-col flex-auto md:min-w-[720px]`}>
      <div className="flex flex-col gap-[8px]">
        <div>
          <span className="font-bold text-lg">Sự cố hiện tại: </span>
          {currentIncidents && currentIncidents.length ? (
            currentIncidents.map((i: any) => (
              <Tag color={"volcano"} className="text-base">
                {incidentTypes.find((type: any) => type.id === i.typeId).name}
              </Tag>
            ))
          ) : (
            <span className="text-base">N/A</span>
          )}
        </div>

        <DatePicker.RangePicker
          className="h-[32px]"
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onCalendarChange={(dates) => {
            setTempFilterRange(dates);
          }}
          onOpenChange={(open) => {
            if (!open) {
              setFilterValue({
                ...filterValue,
                dateRange: tempFilterRange,
              });
            }
          }}
          value={filterValue.dateRange}
          disabledDate={(current) => {
            return moment().add(0, "days") <= current;
          }}
          placeholder={["Từ", "Đến"]}
        />
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          defaultValue={["all"]}
          optionLabelProp="label"
          value={filterValue.typeIds}
          onChange={(value) => {
            const typeIds =
              value[value.length - 1] === "all"
                ? ["all"]
                : value.filter((i: any) => i !== "all");
            setFilterValue({ ...filterValue, typeIds });
          }}
        >
          <Option key="all" value="all" label="Toàn bộ sự cố">
            ---SELECT ALL---
          </Option>
          {incidentTypes.map((i: any) => (
            <Option key={i.id} value={i.id} label={i.name}>
              {i.name} - {i.displayName}
            </Option>
          ))}
        </Select>
        <div className="flex gap-2 items-center justify-between">
          <CheckboxGroup
            value={filterValue.status}
            options={[
              { label: "Chưa kết thúc", value: "0" },
              { label: "Đã kết thúc", value: "1" },
            ]}
            onChange={(status) => {
              setFilterValue({ ...filterValue, status });
            }}
          />
          <Button onClick={resetFilter}>Reset filter</Button>
        </div>
      </div>
      <div className="flex-auto mt-[16px] relative overflow-scroll">
        <Table
          loading={loading}
          rowClassName={({ isEnded }, index) =>
            isEnded === false
              ? "bg-variant-error text-variant-white hover:!text-[#000]"
              : ""
          }
          columns={columns}
          dataSource={dataSource}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            showTotal: (total) => <div>{total} sự cố</div>,
          }}
          bordered
        />
      </div>
    </div>
  );
};

export default DetailIncidents;
