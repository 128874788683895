import { gql } from "@apollo/client";

export type DeviceControlLog = {
  id: number;
  action: number;
  commands: string;
  createdAt: Date;
  inputData: string;
  // """0: fail, 1: pending, 2: processing, 3: done"""
  status: number;
  url: string;
  user: any;
  userId: String;
};

export type Timeslot = {
  start_time: string;
  power: number;
};

export type TimeslotInput = {
  startTime: string;
  value: number;
};

export type ControlLightInput = {
  clientId: string;
  isGroupControl?: boolean;
  nodeIds?: string[];
  value: number;
};

export type ScheduleDimLightInput = {
  clientId: string;
  timeslots: TimeslotInput[];
};

export const LB_RESET_MUTATION = gql`
  mutation DeviceReset($id: String!) {
    deviceReset(id: $id) {
      id
      status
    }
  }
`;

export const LB_DISBR1_MUTATION = gql`
  mutation DeviceDisableBranch1($id: String!, $state: Boolean!) {
    deviceDisableBranch1(id: $id, state: $state) {
      id
      status
    }
  }
`;

export const LB_DISBR2_MUTATION = gql`
  mutation DeviceDisableBranch2($id: String!, $state: Boolean!) {
    deviceDisableBranch2(id: $id, state: $state) {
      id
      status
    }
  }
`;

export const LB_FORCEON_MUTATION = gql`
  mutation deviceForceOn($id: String!, $state: Boolean!) {
    deviceForceOn(id: $id, state: $state) {
      id
      status
    }
  }
`;

export const LB_FORCE_TIMER_MUTATION = gql`
  mutation DeviceSetForceTimer($id: String!, $data: Int!) {
    deviceSetForceTimer(id: $id, data: $data) {
      id
      status
    }
  }
`;

export const LB_MANUAL_TIMER_MUTATION = gql`
  mutation DeviceSetManualTimer($id: String!, $data: Int!) {
    deviceSetManualTimer(id: $id, data: $data) {
      id
      status
    }
  }
`;

export const LB_HPS_DIM_MUTATION = gql`
  mutation deviceSetHpsDimTime(
    $id: String!
    $onTime: String!
    $offTime: String!
  ) {
    deviceSetHpsDimTime(id: $id, onTime: $onTime, offTime: $offTime) {
      id
      status
    }
  }
`;

export const LB_TIMESLOT_MUTATION = gql`
  mutation DeviceSetTimeSlot(
    $id: String!
    $br1_off: String!
    $br1_on: String!
    $br2_off: String!
    $br2_on: String!
  ) {
    deviceSetTimeSlot(
      id: $id
      br1_off: $br1_off
      br1_on: $br1_on
      br2_off: $br2_off
      br2_on: $br2_on
    ) {
      id
      status
    }
  }
`;

export const LB_BULK_TIMESLOT_MUTATION = gql`
  mutation DeviceBulkSetTimeSlot(
    $ids: [Int!]!
    $br1_off: String!
    $br1_on: String!
    $br2_off: String!
    $br2_on: String!
  ) {
    deviceBulkSetTimeSlot(
      ids: $ids
      br1_off: $br1_off
      br1_on: $br1_on
      br2_off: $br2_off
      br2_on: $br2_on
    ) {
      id
      status
      bulkControlNumber
    }
  }
`;

export const LB_SYNCTIME_MUTATION = gql`
  mutation DeviceSyncTime($id: String!) {
    deviceSyncTime(id: $id) {
      id
      status
    }
  }
`;

export const LB_SYNC_LOCATION = gql`
  mutation LightboxSyncLocation($id: Int!) {
    lightboxSyncLocation(id: $id) {
      id
      lat
      lng
    }
  }
`;

export const LB_BULK_SYNC_TIME = gql`
  mutation LightboxBulkSyncTime($ids: [Int!]!) {
    deviceBulkSyncTime(ids: $ids) {
      id
      status
      bulkControlNumber
    }
  }
`;

export const LB_BULK_SYNC_LOCATION = gql`
  mutation LightboxBulkSyncLocation($ids: [Int!]!, $force: Boolean) {
    lightboxSyncLocationAll(ids: $ids, force: $force)
  }
`;

export const LB_CT_TYPE_MUTATION = gql`
  mutation DeviceSetCtType($id: String!, $ctType: Int!) {
    deviceSetCtType(id: $id, ctType: $ctType) {
      id
      status
    }
  }
`;

export const LB_BULK_PMKIND_MUTATION = gql`
  mutation DeviceSetPmkind($ids: [Int!]!, $pmkind: Int!) {
    deviceSetPmkind(ids: $ids, pmkind: $pmkind)
  }
`;

export const LB_BULK_CT_TYPE_MUTATION = gql`
  mutation DeviceBulkSetCtType($ids: [Int!]!, $ctType: Int!) {
    deviceBulkSetCtType(ids: $ids, ctType: $ctType) {
      id
      status
      bulkControlNumber
    }
  }
`;

export const LORA_ALL_LIGHT_ON = gql`
  mutation DeviceAllLightOn($input: ControlLightInput!) {
    deviceAllLightOn(input: $input) {
      id
      status
    }
  }
`;

export const LORA_ALL_LIGHT_DIM = gql`
  mutation DeviceAllLightDim($input: ControlLightInput!) {
    deviceAllLightDim(input: $input) {
      id
      status
    }
  }
`;

export const LORA_SET_DIM_SCHEDULE = gql`
  mutation DeviceSetDimSchedule($input: ScheduleDimLightInput!) {
    deviceSetDimSchedule(input: $input) {
      id
      status
    }
  }
`;

export const GET_CONTROL_LOGS = gql`
  query ($input: DeviceControlLogsInput!, $paging: Paging) {
    deviceControlLogs(input: $input, paging: $paging) {
      id
      action
      bulkControlNumber
      bulkControlDone
      user {
        id
        name
        phoneNumber
      }
      inputData
      status
      createdAt
    }
  }
`;
