import { EnvironmentFilled } from "@ant-design/icons";
import { DeviceState } from "../../../../graphql/lightboxs";

const ClusterMarker = ({ numPoints, status, zoom, state, onClick }: any) => {
  let style = "bg-neutral-d50 border-neutral-l90";
    switch (state) {
      case DeviceState.ERROR:
        style = "bg-variant-error border-[#eb5c52]";
        break;
      case  DeviceState.NORMAL:
        style = "bg-neutral-d50 border-neutral-l90";
        break;
      case  DeviceState.LIGHT_ON:
        style = "bg-variant-success border-[#86df7b]";
        break;
      default:
        style = "bg-variant-warning border-[#f5ce6c]";
        break;
    }
  return (
    <div
      className={`flex items-center justify-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-xl rounded-[50%] border-solid border-4  w-[40px] h-[40px] cursor-pointer opacity-70 hover:w-[48px] hover:h-[48px] hover:opacity-90 ${style}`}
      onClick={onClick}
    >
      {/* <EnvironmentFilled className="text-4xl text-blue-secondary" /> */}
      {numPoints}
    </div>
  );
};

export default ClusterMarker;
