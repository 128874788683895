import * as React from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, Select } from "antd";
import { useDebounce } from "use-debounce";
import {
  GET_LIGHTBOXS_DAHSBOARD_MAP,
  GET_LIGHTBOXS_SEARCH,
  GET_LIGHTBOXS_STATISTIC,
} from "../../graphql/lightboxs";
import "./index.css";
import { Helmet } from "react-helmet";
import CabinetStatistic from "./components/cabinetStatistic";
import DashboardMap from "./components/map/dashboardMap";
import DeviceDetailModal from "../components/DetailModal";
import PoleDetailModal from "../components/LightDetailModal";
import BulkControlModal from "./components/bulkCabinetControl";
import { AuthContext } from "../../context/AuthContext";
import { SlidersOutlined } from "@ant-design/icons";
interface IRemoteRegisterProps {}

const Dashboard: React.FunctionComponent<IRemoteRegisterProps> = (props) => {
  const [statisticData, setStatisticData] = React.useState([]);
  const [selectedLightboxId, setSelectedLightboxId] = React.useState(0);
  const [selectedLightboxIdForPole, setSelectedLightboxIdForPole] =
    React.useState(0);
  const [selectedPoleId, setSelectedPoleId] = React.useState("");
  const [showDetail, setShowDetail] = React.useState(false);
  const [showBulkControl, setShowBulkControl] = React.useState(false);
  const [showPoleDetail, setShowPoleDetail] = React.useState(false);
  // const [showDetailLightbox, setShowDetailLightbox] =
  //   React.useState<any>(undefined);
  const [filterInput, setFilterInput] = React.useState("");
  const [lightboxs, setLightboxs] = React.useState<any[]>([]);
  const [searchlightboxs, setSearchLightboxs] = React.useState([]);
  const [filterText] = useDebounce(filterInput, 600);
  const { userInfo } = React.useContext(AuthContext);

  useQuery(GET_LIGHTBOXS_DAHSBOARD_MAP, {
    variables: {
      input: {},
    },
    fetchPolicy: "cache-first",
    onCompleted(data) {
      setLightboxs(data.lightboxs);
    },
  });

  useQuery(GET_LIGHTBOXS_STATISTIC, {
    pollInterval: 10000,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      setStatisticData(data.lightboxsStatistic);
    },
  });

  const [lightboxsQueryText] = useLazyQuery(GET_LIGHTBOXS_SEARCH);

  React.useEffect(() => {
    lightboxsQueryText({
      variables: {
        input: {
          isRegistered: true,
          text: filterText,
        },
        paging: {
          count: 6,
        },
      },
      onCompleted(data) {
        setSearchLightboxs(data.lightboxs);
      },
    });
  }, [filterText, lightboxsQueryText]);

  React.useEffect(() => {
    if (selectedLightboxId) {
      setSelectedLightboxIdForPole(selectedLightboxId);
    }
  }, [selectedLightboxId]);

  const handleBoxMapClick = (lightbox: any, show = true) => {
    setSelectedLightboxId(lightbox.id);
    setShowDetail(show);
  };

  const handlePoleMapClick = (pole: any) => {
    setSelectedPoleId(pole.id);
    setShowPoleDetail(true);
  };

  const displayCabinetInfo = (i: any) => {
    let result = `${i.boxId} - ${i.name}`;
    if (i.address && i.address !== "") {
      result += ` (${i.address})`;
    }
    return result;
  };

  return (
    <>
      <div className="flex">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Urbaniti - Trang chủ</title>
        </Helmet>
        <DeviceDetailModal
          visible={showDetail}
          onCancel={() => {
            setShowDetail(false);
          }}
          lightboxId={selectedLightboxId}
        />
        <PoleDetailModal
          showLightboxDetail={handleBoxMapClick}
          visible={showPoleDetail}
          onCancel={() => {
            setShowPoleDetail(false);
          }}
          lightboxId={selectedLightboxIdForPole}
          poleId={selectedPoleId}
        />
        <div className="absolute bg-variant-white py-4 z-10 left-[40px] top-[92px] max-w-[1580px] shadow-lg rounded-md">
          <div className="flex flex-col-reverse md:flex-row gap-4 items-center justify-center px-6">
            <Select
              className="h-[40px] w-[280px]"
              size="large"
              defaultOpen={false}
              showArrow={false}
              allowClear
              showSearch
              filterOption={false}
              placeholder="Tìm tủ..."
              onSearch={(value) => {
                setFilterInput(value);
              }}
              onSelect={(value) => {
                setSelectedLightboxId(value);
                setShowDetail(true);
              }}
              // open={filterText !== "" || selectedLightbox }
              // onChange={(e) => {
              //   setSelectedLightboxId(e);
              //   // setFilterInput(e.target.value);
              //   // setCurrentPage(1);
              // }}
            >
              {searchlightboxs.map((i: any) => (
                <Select.Option
                  key={i.id}
                  value={i.id}
                  label={displayCabinetInfo(i)}
                >
                  {displayCabinetInfo(i)}
                </Select.Option>
              ))}
            </Select>
            <div className="flex gap-4 items-center justify-center">
              <CabinetStatistic
                data={statisticData}
                setSelectedId={setSelectedLightboxId}
                className="flex md:border-solid border-0 border-neutral-d30 border-l-2 pl-4"
              />
              {userInfo!.isAdmin && (
                <div className="flex h-[42px] items-center border-solid border-0 border-neutral-d30 border-l-2 pl-6">
                  <Button
                    title="Điều khiển tủ hàng loạt"
                    icon={<SlidersOutlined className="text-neutral-d50" />}
                    onClick={() => setShowBulkControl(true)}
                  ></Button>
                </div>
              )}
            </div>

            {/* <CreateCabinetModal /> */}
          </div>
        </div>
        <DashboardMap
          lightboxs={lightboxs}
          lightboxsWithState={statisticData}
          onBoxClick={handleBoxMapClick}
          onPoleClick={handlePoleMapClick}
          selectedLightboxId={selectedLightboxId}
          setSelectedLightboxId={setSelectedLightboxId}
        />
      </div>

      <BulkControlModal
        open={showBulkControl}
        handleCancel={() => setShowBulkControl(false)}
      />
    </>
  );
};

export default Dashboard;
