import { gql } from "@apollo/client";
import React from "react";
import { Project } from "./lightboxs";

type Coordinate = {
  lat: number;
  lng: number;
};

export type User = {
  key?: React.Key;
  id: string;
  name: string;
  username: string;
  description?: string;
  phoneNumber: string;
  email?: string;
  createdAt?: Date;
  roleId: number;
  isAdmin?: boolean;
  isSuperAdmin: boolean;
  isActived?: boolean;
  birthDate?: Date;
  mapCoordinate?: Coordinate;
  projects: Project[];
};

export type CreateUserInput = {
  input: {
    confirmPassword: string;
    description: string;
    name: string;
    password: string;
    phoneNumber: string;
    roleId: number;
  };
};

export type UpdateUserInput = {
  input: {
    confirmPassword: string;
    description: string;
    name: string;
    password: string;
    roleId: number;
  };
};

export type LoginRespone = {
  login: {
    user: User;
    token: string;
  };
};

export type ChangePasswordInput = {
  password: string;
  newPassword: string;
  confirmPassword: string;
};

export const LOGIN_MUTATION = gql`
  mutation Login($phoneNumber: String!, $password: String!) {
    login(phoneNumber: $phoneNumber, password: $password) {
      user {
        id
        name
        mapCoordinate {
          lat
          lng
        }
        projects {
          id
          name
        }
        phoneNumber
        username
        roleId
        isAdmin
        isSuperAdmin
      }
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      user {
        id
        name
        mapCoordinate {
          lat
          lng
        }
        projects {
          id
          name
        }
        phoneNumber
        username
        roleId
        isAdmin
        isSuperAdmin
      }
      accessToken
      refreshToken
    }
  }
`;

export const GET_USER = gql`
  query User {
    currentUser {
      id
      name
      mapCoordinate {
        lat
        lng
      }
      projects {
        id
        name
      }
      phoneNumber
      username
      roleId
      isAdmin
      isSuperAdmin
    }
  }
`;

export const GET_USER_ALL = gql`
  query UsersAll($paging: Paging) {
    users(paging: $paging) {
      id
      phoneNumber
      username
      roleId
      description
      isActived
      isSuperAdmin
      name
      role {
        id
        name
      }
      projects {
        id
        name
      }
    }
  }
`;

export const GET_USER_WITH_FILTER = gql`
  query GetUsers($input: FilterUserInput!, $paging: Paging) {
    getUserWithFilter(input: $input, paging: $paging) {
      id
      username
      phoneNumber
      isAdmin
      isActived
      createdAt
    }
    countUserWithFilter(input: $input)
  }
`;

export const CREATE_USER = gql`
  mutation userUpdate($input: CreateUserInput!) {
    userCreate(createUserInput: $input) {
      id
      phoneNumber
      username
      roleId
      description
      isActived
      name
      role {
        id
        name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UserUpdate($input: UpdateUserInput!) {
    userUpdate(updateUserInput: $input) {
      id
      phoneNumber
      username
      roleId
      description
      isActived
      name
      role {
        id
        name
      }
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUser($id: String!) {
    removeUser(id: $id)
  }
`;

export const SET_ACTIVE_USER = gql`
  mutation SetActiveUser($id: String!) {
    setActiveUser(id: $id) {
      id
      username
      phoneNumber
      isAdmin
      isActived
      createdAt
    }
  }
`;

export const CHANGE_PASSWORD_USER = gql`
  mutation ChangePassword(
    $password: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    changePassword(
      password: $password
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    )
  }
`;

export const UPGRADE_ADMIN = gql`
  mutation UpgradeAdmin($id: String!) {
    upgradeAdmin(id: $id) {
      id
      username
      phoneNumber
      isAdmin
      isActived
      createdAt
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($id: String!) {
    resetPassword(id: $id)
  }
`;

export function isPasswordValid(password: string) {
  const rule =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
  return password.match(rule);
}
