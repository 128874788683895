import * as React from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { Checkbox, DatePicker, Select, Spin } from "antd";
import {
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";
import { Line, Bar } from "react-chartjs-2";
import {
  GET_DEVICE_POWER_DATA,
  GET_DEVICE_TIMESCALE_VOLTAGE,
  GET_DEVICE_TIMESCALE_CURRENT,
} from "../../../../../graphql/devices";
ChartJS.register(
  TimeScale, //Register timescale instead of category for X axis
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
  //   ...registerables
);
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
interface IDetailChartsProps {
  deviceId: string;
}

const colors = ["74, 66, 205", "255, 99, 132", "83, 200, 82"];

export const getChartOptions: any = (
  title: string,
  displayLegend = true,
  zoom = true
) => ({
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      type: "time",
      display: true,
      time: {
        minUnit: "hour",
        format: "HH:mm",
        displayFormats: {
          hour: "HH:mm",
        },
      },
      title: {
        display: true,
        text: displayLegend ? "Thời gian" : "Giờ",
      },
    },
    y: {
      title: {
        display: true,
        text: displayLegend ? title : "kWh",
        font: {
          size: 16,
        },
      },
      stacked: false,
      beginAtZero: true,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: displayLegend,
      position: "top" as const,
      labels: {
        font: {
          size: 14,
          family: "san-serif",
        },
      },
    },
    zoom: zoom
      ? {
          zoom: {
            wheel: {
              enabled: true,
              //   mode: "x",
            },
            pinch: {
              enabled: true,
            },
            mode: "xy",
          },
          pan: {
            enabled: true,
            mode: "xy",
          },
        }
      : undefined,
    title: {
      display: true,
      text: title,
      color: "#000",
    },
  },
});

enum ChartType {
  KWH,
  VOLTAGE,
  CURRENT,
  POWER,
  PF,
}

const getChartData = (data: any[], type: ChartType) => {
  let keys = [];
  let title = "";
  let fill: any = false;
  let datasets;
  switch (type) {
    case ChartType.KWH:
      fill = {
        target: "start",
      };
      keys = ["kWh"];
      title = "Điện năng tiêu thụ trong ngày";
      break;
    case ChartType.VOLTAGE:
      keys = ["v1n", "v2n", "v3n"];
      title = "Điện áp";
      break;
    case ChartType.PF:
      keys = ["PF1", "PF2", "PF3"];
      break;
    case ChartType.CURRENT:
      keys = ["i1", "i2", "i3"];
      title = "Dòng điện";
      break;
    default:
      keys = ["kw1", "kw2", "kw3"];
      break;
  }

  if (type === ChartType.VOLTAGE || type === ChartType.CURRENT) {
    datasets = keys.map((key, index) => {
      const d = data.map((i: any) => ({
        x: i.time,
        y: i[key],
      }));
      const result = [d[0]];
      let pre = result[0];
      let existSame = false;
      for (let k = 1; k < d.length - 1; k++) {
        const cur = d[k];
        if (pre.y !== cur.y) {
          if (existSame) {
            result.push(pre);
            existSame = false;
          }
          result.push(cur);
        } else {
          existSame = true;
        }
        pre = cur;
      }
      result.push(d[d.length - 1]);
      return {
        label: keys.length > 1 ? `Pha ${index + 1}` : "kWh",
        data: result,
        fill: fill,
        tension: 0.05,
        borderColor: `rgb(${colors[index]})`,
        backgroundColor: `rgba(${colors[index]}, 0.5)`,
      };
    });
  } else {
    datasets = keys.map((key, index) => ({
      label: keys.length > 1 ? `Pha ${index + 1}` : "kWh",
      data: data.map((i: any) => ({
        x: i.time,
        y: i[key],
      })),
      fill: fill,
      tension: 0.05,
      borderColor: `rgb(${colors[index]})`,
      backgroundColor: `rgba(${colors[index]}, 0.5)`,
    }));
  }
  return { datasets, title };
};

const DetailCharts: React.FunctionComponent<IDetailChartsProps> = ({
  deviceId,
}) => {
  const dateFormat = "YYYY-MM-DD HH:mm";
  const defaultFilterRange: [moment.Moment, moment.Moment] = [
    moment().subtract(1, "days"),
    moment(),
  ];
  const [tempFilterRange, setTempFilterRange] =
    React.useState<any>(defaultFilterRange);

  const [chartType, setChartType] = React.useState<ChartType>(ChartType.KWH);
  const [errorFilter, setErrorFilter] = React.useState(false);

  const defaultFilterValue = {
    dateRange: defaultFilterRange,
  };

  const [filterValue, setFilterValue] = React.useState<any>(defaultFilterRange);

  // const { data: allData, loading: loadingAll } = useQuery<any, any>(
  //   GET_DEVICE_TIMESCALE_DATA,
  //   {
  //     variables: {
  //       id: deviceId,
  //       from: filterValue[0],
  //       to: filterValue[1],
  //     },
  //   }
  // );

  const [getVoltageData, { data: voltageData, loading: loadingVoltage }] =
    useLazyQuery<any, any>(GET_DEVICE_TIMESCALE_VOLTAGE, {
      fetchPolicy: "cache-first",
    });

  const [getCurrentData, { data: currentData, loading: loadingCurrent }] =
    useLazyQuery<any, any>(GET_DEVICE_TIMESCALE_CURRENT, {
      fetchPolicy: "cache-first",
    });

  const { data: powerData, loading: loadingPower } = useQuery<any, any>(
    GET_DEVICE_POWER_DATA,
    {
      variables: {
        id: deviceId,
      },
    }
  );

  const resetFilter = () => {
    setFilterValue(defaultFilterValue);
  };

  const renderPowerChart = React.useMemo(() => {
    if (!powerData) return <></>;
    const data = powerData.devicePowerInDay;
    const data1 = powerData.devicePowerInMonth;
    const barData = getChartData(data1, chartType);
    const datasetsBar = barData.datasets;
    const { datasets, title } = getChartData(data, chartType);
    const height = 160;
    return (
      <>
        <Line
          options={getChartOptions(title, false, false)}
          height={height}
          data={{ datasets }}
        />
        <Bar
          height={height}
          options={{
            scales: {
              x: {
                type: "time",
                display: true,
                time: {
                  minUnit: "day",
                  displayFormats: {
                    day: "DD-MM",
                  },
                  tooltipFormat: "DD-MM-YYYY",
                },
                title: {
                  display: true,
                  text: "Ngày",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "kWh",
                  font: {
                    size: 16,
                  },
                },
                stacked: false,
                beginAtZero: true,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              // zoom: {
              //   zoom: {
              //     wheel: {
              //       enabled: true,
              //       //   mode: "x",
              //     },
              //     pinch: {
              //       enabled: true,
              //     },
              //     mode: "xy",
              //   },
              //   pan: {
              //     enabled: true,
              //     mode: "xy",
              //   },
              // },
              title: {
                display: true,
                text: "Điện năng tiêu thụ trong 30 ngày gần nhất",
                color: "#000",
              },
            },
          }}
          data={{ datasets: datasetsBar }}
        />
      </>
    );
  }, [powerData, chartType]);

  const renderVoltageChart = React.useMemo(() => {
    if (!voltageData) return <></>;
    const data = voltageData.deviceTimescaleVoltageData;
    const { datasets, title } = getChartData(data, chartType);
    const height = 160;
    return (
      <>
        <Line
          options={getChartOptions(title, true)}
          height={height}
          data={{ datasets }}
        />
      </>
    );
  }, [voltageData, chartType]);

  const renderCurrentChart = React.useMemo(() => {
    if (!currentData) return <></>;
    const data = currentData.deviceTimescaleCurrentData;
    const { datasets, title } = getChartData(data, chartType);
    const height = 160;
    return (
      <>
        <Line
          options={getChartOptions(title, true)}
          height={height}
          data={{ datasets }}
        />
      </>
    );
  }, [currentData, chartType]);

  React.useEffect(() => {
    if (moment(filterValue[1]).diff(moment(filterValue[0]), "days") > 7) {
      setErrorFilter(true);
      return;
    }
    setErrorFilter(false);
    const variables = {
      id: deviceId,
      from: filterValue[0],
      to: filterValue[1],
    };
    switch (chartType) {
      case ChartType.VOLTAGE:
        getVoltageData({ variables });
        break;
      case ChartType.CURRENT:
        getCurrentData({ variables });
        break;
    }
  }, [chartType, filterValue]);

  const renderChart = () => {
    switch (chartType) {
      case ChartType.KWH:
        return renderPowerChart;
      case ChartType.VOLTAGE:
        return renderVoltageChart;
      case ChartType.CURRENT:
        return renderCurrentChart;
    }
  };

  return (
    <div className={`flex flex-col flex-auto md:min-w-[720px] px-8`}>
      <div className="flex flex-col gap-[8px] pb-4">
        <div className="flex items-center">
          <div className="w-[120px]">Loại biểu đồ:</div>
          <Select
            style={{ width: 188 }}
            placeholder="Chọn loại biểu đồ"
            // defaultValue={0}
            value={chartType}
            onChange={(t) => setChartType(t)}
            options={[
              { value: ChartType.KWH, label: "Công suất tiêu thụ" },
              { value: ChartType.VOLTAGE, label: "Điện áp" },
              { value: ChartType.CURRENT, label: "Dòng điện" },
            ]}
          />
          {(loadingVoltage || loadingCurrent || loadingPower) && (
            <Spin className="px-4" tip="Đang tải" />
          )}
        </div>
        {chartType !== ChartType.KWH && (
          <div className="flex items-center">
            <div className="w-[120px]">Khoảng thời gian: </div>
            <DatePicker.RangePicker
              className="h-[32px]"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              onCalendarChange={(dates) => {
                setTempFilterRange(dates);
              }}
              onOpenChange={(open) => {
                if (!open) {
                  setFilterValue(tempFilterRange);
                }
              }}
              value={filterValue}
              disabledDate={(current) => {
                return moment().add(0, "days") <= current;
              }}
              placeholder={["Từ", "Đến"]}
            />
          </div>
        )}
        {errorFilter && (
          <span className="ml-[120px] text-variant-error italic">
            Khoảng thời gian cho phép trong vòng 7 ngày
          </span>
        )}
      </div>
      <div>
        {/* {chartType === ChartType.KWH ? renderPowerChart : renderOtherChart} */}
        {renderChart()}
      </div>
    </div>
  );
};

export default DetailCharts;
