import { EnvironmentFilled } from "@ant-design/icons";

const ClusterMarker = ({ numPoints, status, zoom, type }: any) => {
  let style = "bg-neutral-d50 border-neutral-l90";
  if (type === "registered") style = "bg-[#45ad37] border-[#86df7b]";
  if (type === "device") style = "bg-variant-error border-[#eb5c52]";
  return (
    <div
      className={`flex items-center justify-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-xl rounded-[50%] border-solid border-4  w-[40px] h-[40px] cursor-pointer opacity-70 ${style}`}
    >
      {/* <EnvironmentFilled className="text-4xl text-blue-secondary" /> */}
      {numPoints}
    </div>
  );
};

export default ClusterMarker;
