import { HddFilled } from "@ant-design/icons";

const LightboxPin = ({ id, lightbox, handleClick, unregistered }: any) => {
  return (
    <div
      className="flex flex-col items-center justify-center relative translate-x-[-50%] translate-y-[-50%] cursor-pointer"
      onClick={handleClick}
    >
      <div className="text-sm relative font-medium text-[#964F19] whitespace-nowrap">
        {lightbox.name}
      </div>
      <HddFilled
        className={`text-2xl ${
          unregistered ? "text-neutral-d50" : "text-[#45ad37]"
        }`}
      />
      {!unregistered && (
        <div className="text-sm relative font-medium text-[#964F19] whitespace-nowrap">
          {lightbox.clientId}
        </div>
      )}
    </div>
  );
};
export default LightboxPin;
