import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Form, Input, message, Modal, Select } from "antd";
import * as React from "react";
import { useDebounce } from "use-debounce";
import { REGISTER_DEVICE } from "../../../../graphql/devices";
import {
  GET_LIGHTBOXS_3,
  GET_LIGHTBOXS_INRANGE,
  LightBox,
} from "../../../../graphql/lightboxs";

interface ICreateRemoteRegisterModalProps {
  isDashboard?: boolean;
  onCompleted?: (newRegisterLog: any) => void;
  isModalVisible: boolean;
  setIsModalVisible: any;
  selectedItem: any;
  filter?: any;
}

const CreateRemoteRegisterModal: React.FunctionComponent<
  ICreateRemoteRegisterModalProps
> = ({
  isDashboard = false,
  onCompleted,
  isModalVisible,
  setIsModalVisible,
  selectedItem,
  filter,
  ...props
}: ICreateRemoteRegisterModalProps) => {
  const [form] = Form.useForm();
  const [filterInput, setFilterInput] = React.useState("");
  const [filterText] = useDebounce(filterInput, 600);
  const [inrangeList, setInrangeList] = React.useState<LightBox[]>([]);
  const [searchList, setSearchList] = React.useState<LightBox[]>([]);

  // const [projects, setProjects] = React.useState([]);
  // useQuery(GET_PROJECTS, {
  //   onCompleted(data) {
  //     setProjects(data.projects);
  //   },
  // });
  const [registerDevice, { loading }] = useMutation(REGISTER_DEVICE);
  const [getLightboxInrange] = useLazyQuery(GET_LIGHTBOXS_INRANGE);
  const [lightboxsQueryText] = useLazyQuery(GET_LIGHTBOXS_3);

  React.useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({ id: selectedItem.id });
      form.resetFields(["refId"]);
    }
  }, [isModalVisible]);

  React.useEffect(() => {
    lightboxsQueryText({
      variables: {
        input: {
          ...filter,
          text: filterText,
        },
        paging: {
          count: 8,
        },
      },
      onCompleted(data) {
        setSearchList(data.lightboxs);
      },
    });
  }, [filterText, lightboxsQueryText]);

  React.useEffect(() => {
    if (filter.projectId && selectedItem) {
      getLightboxInrange({
        variables: {
          input: {
            ...filter,
            location: {
              lat: selectedItem.liveData.lat,
              lng: selectedItem.liveData.lng,
            },
          },
          paging: {
            count: 8,
            skip: 0,
          },
        },
        fetchPolicy: "network-only",
        onCompleted(data) {
          setInrangeList(data.lightboxsInRange);
        },
      });
    }
  }, [filter, selectedItem]);

  const showModal = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = async (values: any) => {
    const { id, refId } = values;

    try {
      const res = await registerDevice({
        variables: {
          registerDeviceInput: { id, refId },
        },
      });
      message.success("Đăng kí thành công");
      onCompleted &&
        onCompleted({
          registerLog: res.data.deviceRegisterRemote,
          lightboxId: refId,
          clientId: id,
        });
      setIsModalVisible(false);
    } catch (error: any) {
      const errMsg = error.toString().replace("Error: ", "");
      message.error("Đăng kí thất bại " + errMsg);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal
        title="Đăng kí thiết bị từ xa"
        open={isModalVisible}
        centered
        footer={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            className="flex items-center"
            label="Mã thiết bị"
            name="id"
          >
            <Input className="h-[40px] text-[16px] font-semibold !text-neutral-d70" disabled />
          </Form.Item>
          {/* <Form.Item
            label="Dự án"
            name="projectId"
            rules={[{ required: true, message: "Chọn dự án" }]}
          >
            <Select
              showSearch
              placeholder="Chọn dự án"
              optionLabelProp="label"
              filterOption={true}
              onChange={(value) => {
                setProjectId(value);
                form.resetFields(["refId"]);
              }}
            >
              {projects.map((item: any) => (
                <Select.Option key={item.id} value={item.id} label={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Tủ kết nối"
            className="flex items-center"
            name="refId"
            rules={[{ required: true, message: "Chọn tủ kết nối" }]}
          >
            <Select
              className="h-[40px] min-w-[260px]"
              size="large"
              defaultOpen={false}
              showArrow={false}
              allowClear
              showSearch
              filterOption={false}
              placeholder="Chọn tủ kết nối"
              onSearch={(value) => {
                setFilterInput(value);
              }}
            >
              {filterText !== ""
                ? searchList.map((item: LightBox) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      label={item.name}
                    >
                      {item.boxId} - {item.name}
                    </Select.Option>
                  ))
                : inrangeList.map((i: LightBox) => (
                    <Select.Option
                      key={i.id}
                      value={i.id}
                      label={`${i.boxId} - ${i.name}`}
                    >
                      {i.boxId} - {i.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>

          <div className="w-full text-right">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="w-[180px]"
            >
              Đăng kí
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateRemoteRegisterModal;
