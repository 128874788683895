import { Content } from "antd/lib/layout/layout";
import * as React from "react";
import { useIdleTimer } from "react-idle-timer";
import { AuthContext } from "../../context/AuthContext";
import confirm from "antd/lib/modal/confirm";

interface IYContentProps {
  children: React.ReactNode;
}

const YContent: React.FunctionComponent<IYContentProps> = ({
  children,
  ...props
}) => {
  const { logout } = React.useContext(AuthContext);
  const handleOnIdle = (event: any) => {
    logout();
    confirm({
      content: "Quá thời gian truy cập. Vui lòng đăng nhập lại!",
      okCancel: false
    });
  };

  const handleOnActive = (event: any) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event: any) => {
    console.log("user did something", event);
  };
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 minutes
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <div className="flex-auto bg-variant-white flex flex-col">{children}</div>
  );
};

export default YContent;
