import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { CustomApolloProvider } from "./context/CustomApolloContext";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import RemoteRegister from "./pages/RemoteRegister";
import UserList from "./pages/User";
import reportWebVitals from "./reportWebVitals";
import "./styles/antd.less";
import "./styles/tailwind.css";
import "./index.css";
import NotFound from "./pages/NotFound";
import ChangePassword from "./pages/User/components/ChangePassword";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider>
    <CustomApolloProvider>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<App />}>
              <Route index element={<Dashboard />} />
              <Route path="/registerdevices" element={<RemoteRegister />} />
              <Route path="/users-management" element={<UserList />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
    </CustomApolloProvider>
  </AuthProvider>
);
reportWebVitals();
