import { gql } from "@apollo/client";

export const GET_REGISTER_LOGS = gql`
  query ($input: FilterRegisterLogInput!, $paging: Paging) {
    registerlogs(input: $input, paging: $paging) {
      id
      lightbox {
        id
        name
        boxId
        projectId
      }
      status
      isDirect
      clientId
      createdBy
      createdAt
    }
  }
`;
