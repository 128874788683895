import { useContext } from "react";
import React from "react";
import {
  REFRESH_TOKEN_MUTATION,
} from "../../graphql/users";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { AuthContext } from "../AuthContext";

interface ICustomApolloProviderProps {
  children: React.ReactNode;
}
export const CustomApolloProvider: React.FunctionComponent<
  ICustomApolloProviderProps
> = ({ children, ...props }) => {
  const { logout, authLogin } = useContext(AuthContext);
  const BACKEND_URL = "devices-prod.citisys.vn";
  // const BACKEND_URL = "backend.quasaltech.com";
  const HTTP_LINK = `https://${BACKEND_URL}/graphql`;
  const WEBSOCKET_LINK = `wss://${BACKEND_URL}/subscriptions`;
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (
          path &&
          path[0] !== "login" &&
          extensions.code === "UNAUTHENTICATED"
        ) {
          const refreshToken = localStorage.getItem("refreshToken");
          if (refreshToken) {
            client
              .mutate({
                mutation: REFRESH_TOKEN_MUTATION,
                variables: {
                  token: refreshToken,
                },
              })
              .then((data: any) => {
                if (data && data.data && data.data.refreshToken) {
                  authLogin({ ...data.data.refreshToken });
                }
                else {
                  logout();
                }
              })
              .catch((error: any) => {
                logout();
              });
          }
          else {
            logout();
          }
        }
      });
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("accessToken");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const httpLink = authLink.concat(
    new HttpLink({
      uri: HTTP_LINK,
      // uri: `http://10.8.0.1:7008/graphql`,
    })
  );

  const wsLink = authLink.concat(
    new WebSocketLink({
      uri: WEBSOCKET_LINK,
      options: {
        reconnect: true,
        connectionParams: {
          authToken: localStorage.getItem("accessToken"),
        },
      },
    })
  );

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    // link: from([errorLink, authLink.concat(uploadLink)]),
    link: from([errorLink, link]),
    cache: new InMemoryCache({
      resultCaching: true,
    }),
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
