import { gql } from "@apollo/client";

export type Device = {
  id: string;
  lightboxId: number;
  liveData: any;
};

export const GET_DEVICE = gql`
  query GetDevice($id: String!) {
    getDevice(id: $id) {
      id
      lightbox {
        id
        name
        boxId
        lat
        lng
        registerLog {
          picture
        }
      }
    }
  }
`;

export const GET_DEVICE_TIMESCALE_DATA = gql`
  query DeviceTimescaleData($id: String!, $from: DateTime!, $to: DateTime!) {
    deviceTimescaleData(id: $id, from: $from, to: $to) {
      id
      time
      i1
      i2
      i3
      v1n
      v2n
      v3n
    }
  }
`;

export const GET_DEVICE_TIMESCALE_VOLTAGE = gql`
  query DeviceTimescaleVoltageData($id: String!, $from: DateTime!, $to: DateTime!) {
    deviceTimescaleVoltageData(id: $id, from: $from, to: $to) {
      id
      time
      v1n
      v2n
      v3n
    }
  }
`;

export const GET_DEVICE_TIMESCALE_CURRENT = gql`
  query DeviceTimescaleCurrentData($id: String!, $from: DateTime!, $to: DateTime!) {
    deviceTimescaleCurrentData(id: $id, from: $from, to: $to) {
      id
      time
      i1
      i2
      i3
    }
  }
`;

export const GET_DEVICE_POWER_DATA = gql`
  query DevicePowerData($id: String!) {
    devicePowerInDay(id: $id) {
      time
      kWh
    }
    devicePowerInMonth(id: $id) {
      time
      kWh
    }
  }
`;

export const GET_DEVICE_LIVE_DATA = gql`
  query GetDevice($id: String!) {
    device(id: $id) {
      id
      currentState
      dataDns
      liveData {
        rawData {
          plc
          params
          pmlc
          status
        }
        pmkind
        PF1
        PF2
        PF3
        avg_PF
        avg_i
        avg_ll
        avg_v
        br1_offtime
        br1_ontime
        br1_status
        br2_offtime
        br2_ontime
        br2_status
        ct_primary
        ct_second
        disbr1
        disbr2
        door_state
        force_curtime
        force_remaintime
        force_state
        force_totaltime
        freq
        fw_version
        hps_offtime
        hps_ontime
        hw_version
        i1
        i2
        i3
        i_leak
        imei
        isConnected
        kVA1
        kVA2
        kVA3
        kVAr1
        kVAr2
        kVAr3
        kWh
        kw1
        kw2
        kw3
        lat
        lc_connect
        lng
        manual_curtime
        manual_remaintime
        manual_state
        manual_totaltime
        modem_rtc
        modem_timesrc
        modem_updated
        net_gen
        net_provider
        osc_I1
        osc_I2
        osc_I3
        osc_V
        out1_status
        out2_status
        plc_connect
        pm_connect
        rssi
        rtc_time
        slg_connect
        total_kVA
        total_kVAr
        total_kW
        v1n
        v2n
        v3n
        v12
        v23
        v31
        ct_type
        light_info {
          connectedLight
          onLight
          dimSchedule {
            start_time
            power
          }
          items {
            id
            ctValue
            ptValue
            isAlive
            dim
            lat
            lng
            onOff
          }
        }
      }
    }
  }
`;

export const GET_DEVICE_WITHOUT_PICTURE = gql`
  query GetDevice($id: String!) {
    getDevice(id: $id) {
      id
      lightbox {
        id
      }
    }
  }
`;

export const GET_UNREGISTERED_DEVICES = gql`
  query GetUnregisteredDevices($paging: Paging) {
    devicesForRemoteRegister(paging: $paging) {
      id
      liveData {
        lat
        lng
      }
    }
  }
`;

export const REGISTER_DEVICE = gql`
  mutation DeviceRegisterRemote(
    $registerDeviceInput: RemoteRegisterDeviceInput!
  ) {
    deviceRegisterRemote(registerDeviceInput: $registerDeviceInput) {
      id
      lightbox {
        id
        name
        boxId
        projectId
      }
      status
      isDirect
      clientId
      createdBy
      createdAt
    }
  }
`;
