import { gql } from "@apollo/client";

export const GET_INCIDENTS = gql`
  query {
    incidents {
      id
      time
      clientId
      status
      isConnected
      updatedAt
      device {
        lightbox {
          id
          boxId
          name
          lat
          lng
          registerLog {
            imageUrl
          }
        }
      }
    }
  }
`;

export const GET_INCIDENTS1 = gql`
  query GET_INCIDENTS1 {
    lightboxsWithIncidents {
      id
      boxId
      name
      lat
      lng
      provinceId
      districtId
      registerLog {
        imageUrl
      }
      device {
        id
        incidents {
          id
          time
          incidentType {
            id
            name
            description
            displayName
            seriousLevel
            isCustom
          }
        }
      }
    }
  }
`;

// export const GET_INCIDENTS_DASHBOARD = gql`
//   query GET_INCIDENTS_DASHBOARD {
//     lightboxsWithIncidents {
//       id
//       device {
//         id
//         incidents {
//           id
//           typeId
//           incidentType {
//             id
//             name
//             description
//             displayName
//             seriousLevel
//             isCustom
//           }
//         }
//       }
//     }
//   }
// `;

export const GET_INCIDENTS_DASHBOARD = gql`
  query GET_INCIDENTS_DASHBOARD($lightboxIds: [Int!]!, $paging: Paging) {
    lightboxsWithIncidents(lightboxIds: $lightboxIds, paging: $paging) {
      id
      incidentTypeIds
    }
  }
`;

export const COUNT_INCIDENTS1 = gql`
  query {
    lightboxsWithIncidentsCount
  }
`;

export const GET_INCIDENTS_BY_BOX = gql`
  query IncidentByBox($input: FilterIncidentByBoxInput!, $paging: Paging) {
    incidentByBox(input: $input, paging: $paging) {
      id
      endTime
      isEnded
      note
      time
      typeId
    }
  }
`;

export const GET_INCIDENTS_STATISTIC = gql`
  query IncidentStatistic(
    $input: FilterIncidentStatisticInput!
    $paging: Paging
  ) {
    incidentStatistic(input: $input, paging: $paging) {
      lightbox {
        id
        name
        boxId
        districtId
        provinceId
      }
      incidents {
        id
        endTime
        isEnded
        note
        time
        typeId
      }
      incidentsCount
    }
  }
`;

export const GET_INCIDENT_TYPES = gql`
  query {
    incidentTypes {
      appearLevel
      assignee
      description
      displayName
      errorCode
      id
      isCustom
      name
      note
      reason
      seriousLevel
      solution
    }
  }
`;
