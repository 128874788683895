import { HddFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useEffect } from "react";
import { DeviceState } from "../../../../graphql/lightboxs";

interface ICabinetStatisticDetailProps {
  type: DeviceState;
  value?: number;
  onClick?: () => void;
}
const CabinetStatisticDetail: React.FunctionComponent<
  ICabinetStatisticDetailProps
> = ({ type, value, onClick }) => {
  const [title, setTitle] = React.useState("");
  const [color, setColor] = React.useState("");
  useEffect(() => {
    let title, color;
    switch (type) {
      case DeviceState.ERROR:
        title = "Số tủ đang trong trạng thái lỗi/ mất kết nối";
        color = "text-variant-error";
        break;
      case DeviceState.NORMAL:
        title = "Số tủ đang trong trạng thái nghỉ";
        color = "text-neutral-d50";
        break;
      case DeviceState.LIGHT_ON:
        title = "Số tủ đang trong trạng thái sáng đèn";
        color = "text-variant-success";
        break;
      default:
        title = "Số tủ đang trong trạng thái tiết giảm";
        color = "text-variant-warning";
        break;
    }
    setTitle(title);
    setColor(color);
  }, []);
  return (
    <Tooltip title={title}>
      <div onClick={onClick} className="flex flex-col justify-between items-center cursor-pointer mx-2">
        <HddFilled className={`text-xl ${color}`} />
        {value !== undefined && <div className="font-bold">{value}</div>}
      </div>
    </Tooltip>
  );
};

export default CabinetStatisticDetail;
