import { DeviceState } from "../../../../graphql/lightboxs";
import { ReactComponent as LightBoxOn } from "./icon/on_light_box.svg";
import { ReactComponent as LightBoxError } from "./icon/error_light_box.svg";
import { ReactComponent as LightBoxNormal } from "./icon/normal_light_box.svg";
import { useEffect } from "react";

interface ILightboxPinProps {
  id: number;
  key: any;
  lightbox: any;
  handleClick: (lightbox: any) => void;
  state: DeviceState;
  zoom: number;
  isSelected: boolean;
}

const LightboxPin = ({
  id,
  key,
  lightbox,
  handleClick,
  state,
  zoom,
  isSelected,
  ...props
}: ILightboxPinProps) => {
  const renderIcon = (state: DeviceState) => {
    switch (state) {
      case DeviceState.ERROR:
        return <LightBoxError />;
      case DeviceState.NORMAL:
        return <LightBoxNormal />;
      case DeviceState.LIGHT_ON:
        return <LightBoxOn />;
    }
  };
  return (
    <div
      className="flex flex-col items-center justify-center relative translate-x-[-50%] translate-y-[-50%] cursor-pointer"
      title={`Tủ ${lightbox.boxId} - ${lightbox.name}`}
      onClick={() => handleClick(lightbox)}
    >
      <div
        className={`${
          isSelected ? "text-lg" : "text-sm"
        } relative font-medium text-[#964F19] whitespace-nowrap bg-variant-white`}
      >
        {lightbox.name}
      </div>
      {renderIcon(state)}
      {zoom > 17 && (
        <div className="text-xs relative font-medium text-[#964F19] whitespace-nowrap bg-variant-white">
          {lightbox.boxId}
        </div>
      )}
    </div>
  );
};
export default LightboxPin;
