import { Layout } from "antd";
import * as React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import YContent from "./YContent";
import YFooter from "./YFooter";
import YHeader from "./YHeader";
import { AuthContext } from "../context/AuthContext";
import { IncidentProvider } from "../context/IncidentContext";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_USER } from "../graphql/users";

interface IYLayoutProps {}

const YLayout: React.FunctionComponent<IYLayoutProps> = (props) => {
  const location = useLocation();
  const { userInfo } = React.useContext(AuthContext);
  return (
    <>
      {userInfo ? (
        <IncidentProvider>
          <Layout className="flex flex-col justify-between min-h-[100vh]">
            <YHeader />
            <YContent>
              <Outlet />
            </YContent>
          </Layout>
        </IncidentProvider>
      ) : (
        <Navigate to="/login" replace state={{ from: location }} />
      )}
    </>
  );
};

export default YLayout;
