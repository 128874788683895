import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, message } from "antd";
import * as React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_USER,
  CreateUserInput,
  User,
  isPasswordValid,
} from "../../../../graphql/users";
import { GET_PROJECTS } from "../../../../graphql/project";

interface ICreateImportModalProps {
  isDashboard?: boolean;
  refetchList?: () => any;
}

const CreateImportModal: React.FunctionComponent<ICreateImportModalProps> = ({
  isDashboard = false,
  refetchList,
  ...props
}: ICreateImportModalProps) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [createUser, { loading }] = useMutation<User, CreateUserInput>(
    CREATE_USER
  );

  const [projects, setProjects] = React.useState([]);

  useQuery(GET_PROJECTS, {
    fetchPolicy: "no-cache",
    onCompleted(rs) {
      const sorted = rs.projects.sort(
        (a: any, b: any) => b.createdAt - a.createdAt
      );
      setProjects(
        sorted.map((i: any) => ({
          value: i.id,
          label: i.name,
        }))
      );
    },
  });

  const showModal = () => {
    form.resetFields();
    form.setFieldsValue({ roleId: 2 });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values: any) => {
    try {
      await createUser({
        variables: {
          input: { ...values },
        },
      });
      refetchList && refetchList();
      message.success("Tạo người dùng thành công");
      setIsModalVisible(false);
    } catch (error: any) {
      const errMsg = error.toString().replace("Error: ", "");
      message.error(errMsg);
      // form.setFields([
      //   {
      //     name: "phoneNumber",
      //     errors: [errMsg],
      //   },
      // ]);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <PlusCircleOutlined
        className="active:text-blue-d30hover:text-variant-success hover:text-blue-primary"
        onClick={showModal}
        style={{
          fontSize: "28px",
        }}
      />

      <Modal
        title="Thêm người dùng mới"
        open={isModalVisible}
        centered
        footer={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Số điện thoại"
            name="phoneNumber"
            rules={[
              {
                required: true,
                min: 10,
                message: "Số điện thoại không đúng định dạng",
              },
            ]}
          >
            <Input placeholder="Nhập số điện thoại" className="w-full" />
          </Form.Item>

          <Form.Item
            label="Mật khẩu"
            name="password"
            rules={[
              {
                required: true,
                message: "Nhập mật khẩu",
              },
              () => ({
                validator(rule, value, callback) {
                  if (!value || isPasswordValid(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Mật khẩu phải bao gồm kí tự đặc biệt, in hoa, thường, số và ít nhất 8 kí tự"
                    )
                  );
                },
              }),
            ]}
          >
            <Input placeholder="Nhập mật khẩu" className="w-full" />
          </Form.Item>

          <Form.Item
            label="Xác nhận mật khẩu"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Nhập xác nhận lại mật khẩu",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Mật khẩu không khớp"));
                },
              }),
            ]}
          >
            <Input placeholder="Nhập xác nhận mật khẩu" className="w-full" />
          </Form.Item>

          <Form.Item label="Quyền" name="roleId">
            <Select>
              <Select.Option value={1}>Guest</Select.Option>
              <Select.Option value={2}>Admin</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Tên" name="name">
            <Input placeholder="Nhập tên" className="w-full" />
          </Form.Item>

          <Form.Item label="Mô tả" name="description">
            <Input placeholder="Nhập mô tả" className="w-full" />
          </Form.Item>

          <Form.Item label="Dự án" name="projectIds">
            <Select options={projects} mode="multiple">
              {/* <Select.Option value={1}>Guest</Select.Option>
              <Select.Option value={2}>Admin</Select.Option>
              <Select.Option value={3}>Superadmin</Select.Option> */}
            </Select>
          </Form.Item>

          <div className="w-full text-right">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="w-[180px]"
            >
              Thêm mới
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateImportModal;
