import { ChartArea } from "chart.js";
import { Timeslot } from "../../../../../graphql/lightbox-control";

export const doughnutPlugins = [
  {
    id: "text",
    beforeDatasetsDraw: function (chart: any) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      const sum = chart.data.datasets[0].data.reduce(
        (partialSum: number, a: number) => partialSum + a,
        0
      );
      var fontSize = (height / 160).toFixed(2);
      ctx.font = "bolder 30px san-serif";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      var text = "1000";
      ctx.fillText(
        sum,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
      ctx.save();
    },
  },
];

export const linePlugins = [
  {
    id: "currentTime",
    afterDatasetsDraw: function (chart: any) {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const activePoint = chart.controller.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.tooltipPosition().x;
        const topY = chart.scales["y-axis-0"].top;
        const bottomY = chart.scales["y-axis-0"].bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#e23fa9";
        ctx.stroke();
        ctx.restore();
      }
    },
  },
];

export function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const colorStart = "rgba(255,199,0,0.1)";
  const colorEnd = "rgba(255,199,0,0.6)";

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

export function compareTimeslots(arr1: Timeslot[], arr2: Timeslot[]) {
  return (
    arr1.length === arr2.length &&
    arr1.every((element_1) =>
      arr2.some(
        (element_2) =>
          element_1.start_time === element_2.start_time &&
          element_1.power === element_2.power
      )
    )
  );
}
