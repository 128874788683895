import { Button, InputNumber, Slider } from "antd";
import * as React from "react";
import { SliderMarks } from "antd/lib/slider";
import "./timeslot.css";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
interface IightTimeslotProps {
  index: number;
  timeRange: [string, string];
  data: { start_time: string; power: number }[];
  setData: React.Dispatch<
    React.SetStateAction<{ start_time: string; power: number }[]>
  >;
}

const STEP = 5; // 5 minutes
const MIN_TIME_VALUE = 0 / STEP; // 30 minutes

const LightTimeslot: React.FunctionComponent<IightTimeslotProps> = ({
  index,
  timeRange,
  data,
  setData,
}) => {
  const start_time = moment(timeRange[0], "HHmm");
  const [maxValue, setMaxValue] = React.useState(100);
  const [power, setPower] = React.useState(0);
  const [range, setRange] = React.useState<[number, number]>([0, 0]);

  const formatValue = (value: number) => {
    const temp = start_time.clone();
    const result = temp.add(value * 5, "minutes").format("HH:mm");
    return result;
  };

  const strTime2Value = (timeStr: string, format = "HHmm") => {
    const _time = moment(timeStr, format);
    if (parseInt(timeStr) - parseInt(timeRange[0]) < 0) _time.add("1", "days");
    const result = _time.diff(start_time, "minutes") / STEP;
    return result;
  };

  const value2strTime = (value: number) => {
    return start_time
      .clone()
      .add(value * STEP, "minutes")
      .format("HHmm");
  };

  const getRange = () => {
    setMaxValue(strTime2Value(timeRange[1]));
    setPower(data[index].power);
    setRange([
      strTime2Value(data[index].start_time),
      strTime2Value(data[index + 1].start_time),
    ]);

  };
  React.useEffect(() => {
    getRange();
  }, []);

  React.useEffect(() => {
    setRange([
      strTime2Value(data[index].start_time),
      strTime2Value(data[index + 1].start_time),
    ]);
  }, [data]);

  return (
    <>
      <div className="flex gap-1 md:gap-5">
        <span className="pt-1 hidden md:block">Thời đoạn {index + 1}:</span>
        <span className="pt-1 md:hidden">TĐ{index + 1}:</span>
        <Slider
          className="slider-timeslot w-[60%]"
          min={0}
          max={maxValue}
          range
          
          tooltip={{
            open: true,
            placement: "top",
            getPopupContainer: (triggerNode) => triggerNode.parentNode as any,
            formatter: (value: any) => {
              return <div className="px-1">{formatValue(value)}</div>;
            },
          }}
          //   marks={marks}
          value={range}
          onChange={(v) => {
            if (
              (index === 0 && v[0] !== 0) ||
              v[1] - v[0] < MIN_TIME_VALUE ||
              (index === data.length - 2 && v[1] < maxValue)
            )
              return;
            const preStartValue =
              index !== 0 && strTime2Value(data[index - 1].start_time);
            const nextEndValue =
              index !== data.length - 2 &&
              strTime2Value(data[index + 2].start_time);
            if (
              (preStartValue !== false &&
                v[0] - preStartValue < MIN_TIME_VALUE) ||
              (nextEndValue && nextEndValue - v[1] < MIN_TIME_VALUE)
            )
              return;
            const newData = [...data];
            newData[index].start_time = value2strTime(v[0]);
            newData[index + 1].start_time = value2strTime(v[1]);
            setRange(v);
            setData(newData);
          }}
        />
        <InputNumber
          min={0}
          max={100}
          height={30}
          // width={20}
          className="max-w-[92px]"
          step={5}
          keyboard
          value={power}
          onChange={(v) => {
            if (v !== null) {
              const newData = [...data];
              newData[index].power = v;
              setData(newData);
              setPower(v);
            }
          }}
          addonAfter="%"
          // formatter={(value) => (value ? `${value} %` : "")}
          // parser={(value: any) => value!.replace("%", "")}
        />

        {data.length > 2 && (
          <Button
            title="Xóa thời đoạn"
            onClick={() => {
              const newData = [...data];
              if (index === data.length - 2) {
                newData[index + 1].start_time = timeRange[1];
              }
              newData.splice(index, 1);
              if (newData.length === 2) {
                newData[0].start_time = timeRange[0];
              }
              setData(newData);
            }}
          >
            <DeleteOutlined className="text-variant-error" />
          </Button>
        )}
      </div>
    </>
  );
};

export default LightTimeslot;
