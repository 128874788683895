import * as React from "react";

import { useQuery } from "@apollo/client";
import { Button, DatePicker, Spin, Table, Tag } from "antd";
import type { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { GET_CONTROL_LOGS } from "../../../../../graphql/lightbox-control";
import { ControlAction, ControlState } from "./enum";
import { ReloadOutlined } from "@ant-design/icons";
import { Order } from "../../../../../graphql/common";

interface IControlHistoryProps {
  lightbox?: any;
  isBulkControl?: boolean;
}

const ControlHistory: React.FunctionComponent<IControlHistoryProps> = ({
  lightbox,
  isBulkControl = false,
}) => {
  const dateFormat = "YYYY-MM-DD HH:mm";
  const defaultFilterRange: [moment.Moment, moment.Moment] = [
    moment().startOf("day").subtract(30, "days"),
    moment().endOf("day"),
  ];
  const [tempFilterRange, setTempFilterRange] =
    React.useState<any>(defaultFilterRange);

  const defaultFilterValue = {
    dateRange: defaultFilterRange,
  };

  const [data, setData] = React.useState<any[]>([]);
  const [filterInput, setFilterInput] = React.useState<any>({
    from: defaultFilterRange[0],
    to: defaultFilterRange[1],
  });
  const [filterValue, setFilterValue] = React.useState<any>(defaultFilterValue);

  const { loading, refetch } = useQuery<any, any>(GET_CONTROL_LOGS, {
    variables: {
      input: {
        clientId: lightbox && lightbox.clientId,
        isBulkControl,
        from: filterInput.from,
        to: filterInput.to,
      },
      paging: {
        orderBy: [
          {
            field: "createdAt",
            order: Order.DESC,
          },
        ],
      },
    },
    onCompleted(d) {
      setData(d.deviceControlLogs);
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    pollInterval: 8000,
  });

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 20,
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Hành động",
      dataIndex: "action",
      render: (text) => <>{ControlAction[text]}</>,
    },
    {
      title: "Thông số",
      dataIndex: "inputData",
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      render(_, { createdAt }) {
        const result = moment(createdAt).utcOffset(7, false).format(dateFormat);
        return <>{result}</>;
      },
    },
    {
      title: "Trạng thái",
      render(_, { status }) {
        const value = ControlState[status];
        return <Tag color={value.color}>{value.text}</Tag>;
      },
    },
  ];

  if (isBulkControl) {
    columns.push({
      title: "Số lượng",
      render(_, { status, bulkControlNumber, bulkControlDone }) {
        if (!bulkControlNumber) return <></>;
        const result = `${bulkControlDone}/${bulkControlNumber} tủ`;
        const value = ControlState[status];
        return <Tag color={value.color}>{result}</Tag>;
      },
    });
  }

  React.useEffect(() => {
    const { dateRange } = filterValue;
    setFilterInput({
      from: dateRange[0].startOf("day").toDate(),
      to: dateRange[1].endOf("day").toDate(),
    });
  }, [filterValue]);

  return (
    <div className={`flex flex-col flex-auto md:min-w-[720px]`}>
      <div className="flex items-center gap-[8px]">
        <span>Khoảng thời gian: </span>
        <DatePicker.RangePicker
          className="h-[32px]"
          // showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD"
          onCalendarChange={(dates) => {
            setTempFilterRange(dates);
          }}
          onOpenChange={(open) => {
            if (!open) {
              setFilterValue({
                ...filterValue,
                dateRange: tempFilterRange,
              });
            }
          }}
          value={filterValue.dateRange}
          disabledDate={(current) => {
            return moment().add(0, "days") <= current;
          }}
          placeholder={["Từ", "Đến"]}
        />
        <Button
          icon={<ReloadOutlined className="text-neutral-d50" />}
          title="Tải lại"
          onClick={() => refetch()}
        />
        {loading && <Spin tip="Đang tải" />}
      </div>
      <div className="flex flex-col gap-[8px]"></div>
      <div className="flex-auto mt-[16px] relative overflow-scroll">
        <Table
          loading={loading}
          rowClassName={({ isEnded }) =>
            isEnded === false
              ? "bg-variant-error text-variant-white hover:!text-[#000]"
              : ""
          }
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            showTotal: (total) => <div>{total} hành động</div>,
          }}
          bordered
        />
      </div>
    </div>
  );
};

export default ControlHistory;
