import { createContext, useEffect } from "react";
import React from "react";
import { GET_USER, User } from "../../graphql/users";
import { useLazyQuery } from "@apollo/client";
export type AuthContextData = {
  accessToken?: string;
  userId?: string;
  setAccessToken?: any;
  logout?: any;
  authLogin?: any;
  userInfo?: User;
  setUserInfo?: React.Dispatch<React.SetStateAction<User | undefined>>;
};

export const AuthContext = createContext<AuthContextData>({});

export const useGlobalContext = () => AuthContext.Provider;
interface IAuthProviderProps {
  children: React.ReactNode;
}
export const AuthProvider: React.FunctionComponent<IAuthProviderProps> = ({
  children,
  ...props
}) => {
  const [userInfo, setUserInfo] = React.useState<User | undefined>(undefined);
  // const [getUser] = useLazyQuery(GET_USER, {
  //   onCompleted(data) {
  //     setUserInfo(data.currentUser);
  //   },
  //   onError(error) {
  //     console.error("error", error);
  //   },
  // });

  // useEffect(() => {
  //   if (localStorage.getItem("accessToken")) {
  //     getUser();
  //   }
  // }, []);

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setUserInfo(undefined);
  };

  const authLogin = (data: { accessToken: string; refreshToken: string; user: User }) => {
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);
    setUserInfo(data.user);
  };

  const data: AuthContextData = {
    logout,
    authLogin,
    userInfo,
    setUserInfo,
  };
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};
