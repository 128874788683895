export const ControlState: any = {
    0: {text: "Thất bại", color: "volcano"},
    1: {text: "Đang chờ", color: "blue"},
    2: {text: "Đang xử lí", color: "blue"},
    3: {text: "Hoàn thành", color: "green"},
}
// setTime = 1,
//   setWorkingTime,2
//   setManualTimer,3
//   setForceTimer,4
//   setHpsDimTime,5
//   forceTurnOn,6
//   disableBranh1,7
//   disableBranh2,8
//   setCtRatio,9
//   reset,10
//   setDimInfo,11
//   updateFirmware,12
//   setCustomOff,13
export const ControlAction: any = {
    1: "Cài đặt giờ cho tủ",
    2: "Cài đặt giờ bật tắt nhánh",
    3: "Cài đặt thời gian cưỡng chế bật tủ",
    4: "Cài đặt thời gian bật tủ bằng tay",
    5: "Cài đặt giờ tiết giảm HPS",
    6: "Cưỡng chế bật tủ",
    7: "Tắt nhánh 1",
    8: "Tắt nhánh 2",
    9: "Cài đặt loại công suất tủ ",
    10: "Khởi động lại tủ",
    11: "Cài đặt tiết giảm HPS",
    12: "Cập nhật firmware",
    13: "Cài đặt thời gian tắt (tùy chỉnh)",
    14: "Bật/Tắt toàn bộ đèn",
    15: "Cài đặt tiết giảm toàn bộ đèn",
    16: "Cài đặt thời gian tiết giảm cho đèn",
    17: "Cài đặt thông số Lora",
}