import * as React from "react";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, InputNumber, message } from "antd";
import confirm from "antd/lib/modal/confirm";
import { Chart as ChartJS, registerables } from "chart.js";
import "chartjs-adapter-moment";
import moment from "moment";
import { Chart, Doughnut } from "react-chartjs-2";
import * as ControlAPI from "../../../../../graphql/lightbox-control";
import LightTimeslot from "./components/timeslot";
import {
  compareTimeslots,
  createGradient,
  doughnutPlugins,
  linePlugins,
} from "./helpers";
import { AuthContext } from "../../../../../context/AuthContext";
import { compareTime, isBetweenTimes } from "../../../../../utils/helpers";
import { IncidentContext } from "../../../../../context/IncidentContext";
ChartJS.register(...registerables);
interface ILightManagementProps {
  lightbox: any;
}

const LightManagement: React.FunctionComponent<ILightManagementProps> = ({
  lightbox,
}) => {
  const [lightOnMutation] = useMutation<
    ControlAPI.DeviceControlLog,
    { input: ControlAPI.ControlLightInput }
  >(ControlAPI.LORA_ALL_LIGHT_ON);
  const [lightDimMutation] = useMutation<
    ControlAPI.DeviceControlLog,
    { input: ControlAPI.ControlLightInput }
  >(ControlAPI.LORA_ALL_LIGHT_DIM);
  const [lightDimScheduleMutation] = useMutation<
    ControlAPI.DeviceControlLog,
    { input: ControlAPI.ScheduleDimLightInput }
  >(ControlAPI.LORA_SET_DIM_SCHEDULE);
  const { userInfo } = React.useContext(AuthContext);
  const { poles, setPoles, getPoles, startPolling, stopPolling } =
    React.useContext(IncidentContext)!;
  const [dataLight, setDataLight] = React.useState<any>([0, 0, 0]);
  const [showSetting, setShowSetting] = React.useState(false);
  const [powerValue, setPowerValue] = React.useState<number | undefined>(
    undefined
  );
  const [timeslotDataset, setTimeslotDataset] = React.useState<any>({
    datasets: [],
  });
  const [chartRatio, setChartRatio] = React.useState(2);
  const chartRef = React.useRef<ChartJS>(null);
  const testData: ControlAPI.Timeslot[] = [
    {
      start_time: "1700",
      power: 50,
    },
    {
      start_time: "1900",
      power: 70,
    },
    {
      start_time: "2300",
      power: 100,
    },
    {
      start_time: "0200",
      power: 40,
    },
    {
      start_time: "0530",
      power: 0,
    },
  ];
  const [timeRange, setTimeRange] = React.useState<[string, string]>(["1730", "0500"]);
  const [dataSchedule, setDataSchedule] = React.useState<ControlAPI.Timeslot[]>(
    []
  );
  const [tempDataSchedule, setTempDataSchedule] = React.useState(testData);
  const [onTime, setOntime] = React.useState("1730");
  const [offTime, setOfftime] = React.useState("0500");

  React.useEffect(() => {
    setChartRatio(window.innerWidth > 768 ? 2 : 1.5);
  }, []);

  const isLightConnected =
    lightbox &&
    lightbox.device &&
    lightbox.device.liveData &&
    lightbox.device.liveData.light_info &&
    lightbox.device.liveData.light_info.connectedLight &&
    lightbox.device.liveData.light_info.items;
  React.useEffect(() => {
    if (isLightConnected && !showSetting) {
      const d = lightbox.device.liveData;
      const connectedLight = d.light_info.connectedLight || 0;
      const items = lightbox.device.liveData.light_info.items;
      const onLight =
        items.filter((i: any) => i.isAlive && i.onOff).length || 0;
      const offLight =
        items.filter((i: any) => i.isAlive && !i.onOff).length || 0;
      let offlineLight = connectedLight - onLight - offLight;
      if (offlineLight < 0) offlineLight = 0;
      setDataLight([onLight, offLight, offlineLight]);
      const on_time =
        (compareTime(d.br1_ontime, d.br2_ontime) !== -1
          ? d.br2_ontime
          : d.br1_ontime) || onTime;
      const off_time = 
        (compareTime(d.br1_offtime, d.br2_offtime) !== -1
          ? d.br1_offtime
          : d.br2_offtime) || offTime ;
      setOntime(on_time);
      setOntime(off_time);
      setTimeRange([on_time, off_time]);
      if (d.light_info.dimSchedule) {
        // const chart = chartRef.current;
        // if (!chart) {
        //   return;
        // }
        // const lineChartData = {
        //   datasets: [
        //     {
        //       data: d.light_info.dimSchedule.map(
        //         (i: any) => ({
        //           x: `${i.start_time.slice(0, 2)}:${i.start_time.slice(2)}`,
        //           y: i.power,
        //         })
        //       ),
        //       fill: {
        //         target: "start",
        //         above: createGradient(chart.ctx, chart.chartArea),
        //       },
        //       stepped: true,
        //       borderColor: `rgb(255,199,0)`,
        //     },
        //   ],
        // };
        const currentSlotIndex = d.light_info.dimSchedule.findIndex(
          (e: any, i: number, a: any[]) =>
            i !== a.length - 1 &&
            isBetweenTimes(on_time, e.start_time, a[i + 1].start_time)
        );
        let _dimSchedule = [...d.light_info.dimSchedule];
        if (currentSlotIndex > -1) {
          _dimSchedule = [...d.light_info.dimSchedule.slice(currentSlotIndex)];
          _dimSchedule[0].start_time = on_time;
        }
        if (_dimSchedule[_dimSchedule.length - 1].start_time !== off_time) {
          _dimSchedule.push({ start_time: off_time, power: 0 });
        }
        setDataSchedule(_dimSchedule);
      }
    }
  }, [lightbox, showSetting]);

  React.useEffect(() => {
    if (dataSchedule && dataSchedule.length > 0) {
      const chart = chartRef.current;
      if (!chart) {
        return;
      }
      const lineChartData = {
        datasets: [
          {
            data: dataSchedule.map((i: any) => ({
              x: `${i.start_time.slice(0, 2)}:${i.start_time.slice(2)}`,
              y: i.power,
            })),
            fill: {
              target: "start",
              above: createGradient(chart.ctx, chart.chartArea),
            },
            stepped: true,
            borderColor: `rgb(255,199,0)`,
          },
        ],
      };
      setTimeslotDataset(lineChartData);
    }
  }, [dataSchedule]);

  const handleOnOff = (isOn: boolean) => {
    const stateText = isOn ? "Bật" : "Tắt";
    confirm({
      okText: "Xác nhận",
      cancelText: "Hủy",
      icon: <ExclamationCircleOutlined />,
      content: `${stateText} toàn bộ đèn của tủ?`,
      onOk() {
        lightOnMutation({
          variables: {
            input: {
              clientId: lightbox.clientId,
              isGroupControl: true,
              value: isOn ? 1 : 0,
            },
          },
        })
          .then(() => {
            message.success("Gửi lệnh thành công!");
            stopPolling();
            setTimeout(() => {
              setPoles((poles) => {
                return poles.map((p) => ({ ...p, onOff: isOn }));
              });
            }, 1500);
            setTimeout(() => {
              startPolling(10000);
            }, 6000);
          })
          .catch((err: any) => message.error(err));
      },
      onCancel() {},
    });
  };

  const handleDim = () => {
    confirm({
      okText: "Xác nhận",
      cancelText: "Hủy",
      icon: <ExclamationCircleOutlined />,
      content: `Điều chỉnh toàn bộ đèn của tủ ở mức ${powerValue}% ?`,
      onOk() {
        lightDimMutation({
          variables: {
            input: {
              value: powerValue!,
              clientId: lightbox.clientId,
              isGroupControl: true,
            },
          },
        })
          .then(() => {
            message.success("Gửi lệnh thành công!");
            stopPolling();
            setTimeout(() => {
              setPoles((poles) => {
                return poles.map((p) => ({ ...p, dim: powerValue }));
              });
            }, 1500);
            setTimeout(() => {
              startPolling(10000);
            }, 6000);
          })
          .catch((err: any) => message.error(err));
      },
      onCancel() {},
    });
  };

  const handleDimSchedule = () => {
    confirm({
      okText: "Xác nhận",
      cancelText: "Hủy",
      icon: <ExclamationCircleOutlined />,
      content: `Điều chỉnh thời gian tiết giảm?`,
      onOk() {
        lightDimScheduleMutation({
          variables: {
            input: {
              clientId: lightbox.clientId,
              timeslots: dataSchedule.map((i: any) => ({
                startTime: i.start_time,
                value: i.power,
              })),
            },
          },
        })
          .then(() => {
            message.success("Gửi lệnh thành công!");
          })
          .catch((err: any) => message.error(err));
      },
      onCancel() {},
    });
  };

  return isLightConnected ? (
    <div className={`flex flex-col h-fit md:min-w-[720px] md:px-2 gap-4`}>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full justify-center h-[204px] md:h-[264px]">
          <Doughnut
            options={{
              aspectRatio: 1,
              responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    font: {
                      size: 16,
                      family: "san-serif",
                      // weight: "bold"
                    },
                    generateLabels: (chart: any) => {
                      const datasets = chart.data.datasets;
                      return datasets[0].data.map((data: any, i: any) => ({
                        //   text: JSON.stringify({"a": data, "b": 200}, null, '\t'),
                        text: `${data.toString().padEnd("5", " ")} ${
                          chart.data.labels[i]
                        } `,
                        fillStyle: datasets[0].backgroundColor[i],
                        hidden: !chart.getDataVisibility(i),
                        index: i,
                      }));
                    },
                  },
                },
                title: {
                  position: "bottom",
                  text: "Thông số đèn hiện tại",
                  display: true,
                },
              },
            }}
            //   height={80}
            plugins={doughnutPlugins}
            data={{
              labels: ["Đèn đang sáng", "Đèn đang tắt", "Đèn mất kết nối"],
              datasets: [
                {
                  // label: "My First Dataset",
                  data: dataLight,
                  backgroundColor: ["#24ce7c", "#7E818A", "#ce2424"],
                  hoverOffset: 4,
                },
              ],
            }}
          />
        </div>
        <div className="flex w-full md:w-[528px] justify-center h-[204px] md:h-[264px]">
          <Chart
            type="line"
            ref={chartRef}
            plugins={[
              {
                id: "currentTime",
                afterDatasetDraw: function (chart: any) {
                  const dataSchedule: any[] = chart.data.datasets[0].data.map(
                    (i: any) => ({
                      start_time: i.x.replace(":", ""),
                    })
                  );
                  if (
                    !dataSchedule ||
                    dataSchedule.length < 1 ||
                    !lightbox.device.liveData.rtc_time
                  )
                    return;
                  const now = moment(
                    lightbox.device.liveData.rtc_time.toString()
                  );
                  const nowStr = now.format("HHmm");
                  const currentSlotIndex = dataSchedule.findIndex(
                    (e, i, a) =>
                      i !== a.length - 1 &&
                      isBetweenTimes(nowStr, e.start_time, a[i + 1].start_time)
                  );
                  if (currentSlotIndex === -1) return;

                  const startTime = dataSchedule[currentSlotIndex].start_time;
                  const endTime = dataSchedule[currentSlotIndex + 1].start_time;
                  const ctx = chart.ctx;
                  const _start = moment(startTime, "HHmm");
                  const _end = moment(endTime, "HHmm");
                  if (_end < _start) {
                    _end.add(1, "days");
                  }
                  let diff = currentSlotIndex / (dataSchedule.length - 1);
                  diff +=
                    now.diff(_start, "minutes") /
                    (_end.diff(_start, "minutes") * (dataSchedule.length - 1));
                  const x = chart.scales.x.width * diff + chart.scales.x.left;
                  const topY = chart.scales.y.top - 10;
                  const bottomY = chart.scales.y.bottom + 10;
                  ctx.textAlign = "center";
                  ctx.font = "12px Arial";
                  // console.log("dataSchedule", dataSchedule.length, x, chart.scales.x.width, topY);
                  ctx.fillText(now.format("HH:mm"), x, topY + 10);
                  ctx.setLineDash([5, 3]);
                  ctx.beginPath();
                  ctx.moveTo(x, topY + 15);
                  ctx.font = "16px serif";
                  ctx.lineTo(x, bottomY);
                  ctx.lineWidth = 2;
                  ctx.strokeStyle = "#ff4d4f";
                  ctx.stroke();
                },
              },
            ]}
            options={{
              // aspectRatio: 2,
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                x: {
                  offset: false,
                },
                y: {
                  beginAtZero: true,
                  max: 100,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  position: "bottom",
                  text: "Biểu đồ tiết giảm đèn hiện tại",
                  display: true,
                },
              },
            }}
            width={window.innerWidth > 768 ? 528 : "100%"}
            // height={"100%"}
            // plugins={plugins}
            data={timeslotDataset}
          />
        </div>
      </div>
      {userInfo && (
        <>
          <span
            onClick={() => setShowSetting(!showSetting)}
            className="w-fit italic text-blue-primary cursor-pointer hover:text-blue-secondary hover:underline hover:font-semibold"
          >
            Thay đổi cài đặt/điều khiển
          </span>
          {/* Setting */}
          {showSetting && (
            <div className="flex flex-col gap-2">
              <div className="border-solid rounded border-[1px] border-neutral-l90 p-2">
                <div className="pb-4 font-semibold">Điều khiển tức thời</div>
                <div className="flex flex-col md:flex-row gap-2 px-1 md:gap-14 md:px-6">
                  {userInfo.isSuperAdmin && (
                    <div className="flex gap-2 items-center">
                      <span>Trạng thái:</span>
                      <Button
                        className="bg-[#24ce7c]"
                        onClick={() => handleOnOff(true)}
                      >
                        Bật
                      </Button>
                      <Button
                        className="bg-[#7E818A] text-variant-white"
                        onClick={() => handleOnOff(false)}
                      >
                        Tắt
                      </Button>
                    </div>
                  )}

                  <div className="flex gap-2 items-center ">
                    <span>Mức công suất (%):</span>
                    <InputNumber
                      placeholder="Nhập số"
                      min={0}
                      max={100}
                      step={5}
                      keyboard
                      value={powerValue}
                      onChange={(v: any) => setPowerValue(v)}
                      defaultValue={undefined}
                      // formatter={(value) => (value ? `${value} %` : "")}
                      // parser={(value: any) => value!.replace("%", "")}
                    />
                    <Button
                      type="primary"
                      disabled={!powerValue}
                      onClick={handleDim}
                    >
                      Cài đặt
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-span-4 border-solid rounded border-[1px] border-neutral-l90 p-2">
                <div className="pb-6 font-semibold">
                  Khoảng thời gian tiết giảm:
                </div>
                <div className="flex flex-col gap-4 px-1 md:px-6">
                  {timeRange &&
                    dataSchedule
                      .slice(0, -1)
                      .map((item, index) => (
                        <LightTimeslot
                          index={index}
                          timeRange={timeRange}
                          data={dataSchedule}
                          setData={setDataSchedule}
                        />
                      ))}
                  <div className="flex px-4 gap-1 md:gap-4 justify-center">
                    <Button
                      onClick={() => {
                        setDataSchedule(dataSchedule);
                      }}
                    >
                      Hủy bỏ
                    </Button>
                    <Button
                      disabled={dataSchedule.length > 6}
                      onClick={() => {
                        const d = [...dataSchedule];
                        let newItem;
                        let lastItem;
                        if (d.length === 0) {
                          newItem = { start_time: onTime, power: 100 };
                          lastItem = { start_time: offTime, power: 0 };
                        } else {
                          lastItem = d.pop();
                          newItem = { ...d[d.length - 1] };
                          newItem.start_time = moment(
                            d[d.length - 1].start_time,
                            "HHmm"
                          )
                            .add(30, "minutes")
                            .format("HHmm");
                        }
                        d.push(newItem, lastItem!);
                        setDataSchedule(d);
                      }}
                    >
                      Thêm thời đoạn
                    </Button>
                    <Button
                      type="primary"
                      disabled={compareTimeslots(dataSchedule, testData)}
                      onClick={handleDimSchedule}
                    >
                      Thay đổi
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <div className="min-h-[144px] md:min-w-[720px] flex items-center justify-center text-2xl">
      Tủ chưa kết nối đèn!
    </div>
  );
};

export default LightManagement;
