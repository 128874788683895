import { gql } from "@apollo/client";

export const GET_PROVINCE = gql`
  query ($lat: Float!, $lng: Float!) {
    provinceByPoint(lat: $lat, lng: $lng) {
      id
      name
      displayName
      geometry {
        coordinates {
          lat
          lng
        }
      }
    }
  }
`;

export const GET_PROVINCES = gql`
  query {
    provinces {
      id
      name
      displayName
    }
  }
`;

export const GET_PROVINCES_ON_DEPLOY = gql`
  query {
    provincesOnDeploy {
      id
      name
      displayName
    }
  }
`;

export const GET_REGIONS_ON_DEPLOY = gql`
  query RegionsOnDeploy {
    regionsOnDeploy {
      provinces {
        id
        name
        displayName
      }
      districts {
        id
        name
        displayName
      }
    }
  }
`;

export const GET_DISTRICTS = gql`
  query Districts($provinceId: String!) {
    districts(provinceId: $provinceId) {
      id
      name
      displayName
    }
  }
`;

export const GET_DISTRICTS_ON_DEPLOY = gql`
  query Districts($provinceIds: [String!]!) {
    districtsOnDeploy(provinceIds: $provinceIds) {
      id
      name
      displayName
      provinceId
    }
  }
`;

export const GET_DISTRICT = gql`
  query districtByPoint($lat: Float!, $lng: Float!) {
    districtByPoint(lat: $lat, lng: $lng) {
      id
      geometry {
        coordinates {
          lat
          lng
        }
      }
    }
  }
`;

export const GET_DISTRICT_SIMPLE = gql`
  query districtByPoint($lat: Float!, $lng: Float!) {
    districtByPoint(lat: $lat, lng: $lng) {
      id
    }
  }
`;

export const GET_WARD = gql`
  query ($lat: Float!, $lng: Float!) {
    wardtByPoint(lat: $lat, lng: $lng) {
      id
      name
      displayName
      geometry {
        coordinates {
          lat
          lng
        }
      }
    }
  }
`;

export const GET_WARD_SIMPLE = gql`
  query wardByPoint($lat: Float!, $lng: Float!) {
    wardByPoint(lat: $lat, lng: $lng) {
      id
      districtId
      provinceId
    }
  }
`;
