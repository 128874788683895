import { createContext } from "react";
import React from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_DISTRICTS_ON_DEPLOY,
  GET_PROVINCES_ON_DEPLOY,
} from "../../graphql/location";
import { GET_POLES } from "../../graphql/poles";
export type IncidentContextData = {
  incidents: any[];
  errorCount: number;
  setErrorCount: React.Dispatch<React.SetStateAction<number>>;
  provinces: any[];
  districts: any[];
  poles: any[];
  setDistricts: React.Dispatch<React.SetStateAction<any[]>>;
  setProvinces: React.Dispatch<React.SetStateAction<any[]>>;
  setPoles: React.Dispatch<React.SetStateAction<any[]>>;
  getPoles: any;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
};

export const IncidentContext = createContext<IncidentContextData | null>(null);

export const useGlobalContext = () => IncidentContext.Provider;
interface IIncidentProviderProps {
  children: React.ReactNode;
}
export const IncidentProvider: React.FunctionComponent<
  IIncidentProviderProps
> = ({ children, ...props }) => {
  const [incidents, setIncidents] = React.useState([]);
  const [errorCount, setErrorCount] = React.useState(0);
  const [districts, setDistricts] = React.useState<any[]>([]);
  const [provinces, setProvinces] = React.useState<any[]>([]);

  const [poles, setPoles] = React.useState<any[]>([]);
  const [getPoles, { startPolling, stopPolling }] = useLazyQuery(GET_POLES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      setPoles(data.poles);
      startPolling(10000);
    },
  });

  const data: IncidentContextData = {
    incidents,
    errorCount,
    setErrorCount,
    districts,
    provinces,
    setDistricts,
    setProvinces,
    poles,
    setPoles,
    getPoles,
    startPolling,
    stopPolling,
  };
  return (
    <IncidentContext.Provider value={data}>{children}</IncidentContext.Provider>
  );
};
