import { EnvironmentFilled } from "@ant-design/icons";

const CurrentLocation = ({ name, status, zoom }: any) => {
  return (
    <div className="relative top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
      <div className="w-10 h-10 flex items-center justify-center rounded-[50%] border-solid border-[10px] border-[#4285f4] border-opacity-30">
        <div className="w-5 h-5 flex rounded-[50%] border-solid border-[2px] border-variant-white bg-[#4285f4]" />
      </div>
    </div>
  );
};

export default CurrentLocation;
