import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
// @ts-ignore
import supercluster from "points-cluster";
import LightboxPin from "./lightboxPin";
import CurrentLocation from "../../../components/Map/currentLocation";
import { DeviceState } from "../../../../graphql/lightboxs";
import ClusterMarker from "./clusterMaker";
import PolePin from "./polePin";
import { AuthContext } from "../../../../context/AuthContext";
import { IncidentContext } from "../../../../context/IncidentContext";
import { AimOutlined } from "@ant-design/icons";

interface IGMapProps {
  lightboxs: any[];
  lightboxsWithState: any[];
  onBoxClick: (item: any, show: boolean) => void;
  onPoleClick: (item: any) => void;
  selectedLightboxId?: number;
  setSelectedLightboxId: Dispatch<SetStateAction<number>>;
}

const ZOOM_DETAIL = 19;
const ZOOM_TIMEOUT_OFFSET = 800;
const MIN_ZOOM_DETAIL = 16;
const MAX_ZOOM_CLUSTER = 16;

const DashboardMap = ({
  lightboxs,
  lightboxsWithState,
  onBoxClick,
  onPoleClick,
  selectedLightboxId,
  setSelectedLightboxId,
}: IGMapProps) => {
  const { userInfo } = React.useContext(AuthContext);
  const { poles, setPoles, getPoles, startPolling, stopPolling } =
    React.useContext(IncidentContext)!;

  const defaultMapProps = {
    center: {
      lat:
        userInfo && userInfo.mapCoordinate
          ? userInfo.mapCoordinate.lat
          : 10.756138200595704,
      lng:
        userInfo && userInfo.mapCoordinate
          ? userInfo.mapCoordinate.lng
          : 106.66918725571473,
    },
    zoom: 13,
  };
  const [preMapProps, setPreMapProps] = useState<any>(defaultMapProps);
  const [mapProps, setMapProps] = useState<any>(defaultMapProps);

  const [lightboxsNormal, setLightboxsNormal] = useState<any>([]); //registered
  const [lightboxsError, setLightboxsError] = useState<any>([]); //registered
  const [lightboxsLightOn, setLightboxsLightOn] = useState<any>([]); //registered

  const [clusterNormal, setClusterNormal] = useState<any>([]); //registered
  const [clusterError, setClusterError] = useState<any>([]); //registered
  const [clusterLightOn, setClusterLightOn] = useState<any>([]); //registered
  const [selectedLightbox, setSelectedLightbox] = useState<any>();

  const [position, setPosition] = useState<any>(defaultMapProps.center);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [watchPositionId, setWatchPositionId] = useState(0);

  const clusterProps = {
    minZoom: 3, // min zoom to generate clusters on
    maxZoom: 16, // max zoom level to cluster the points on
    radius: 100, // cluster radius in pixels
    minPoints: 2,
  };

  const clNormal = supercluster(lightboxsNormal, clusterProps);
  const clError = supercluster(lightboxsError, clusterProps);
  const clLightOn = supercluster(lightboxsLightOn, clusterProps);

  const mapCluster = ({ wx, wy, numPoints, points }: any) => ({
    lat: wy,
    lng: wx,
    lightboxs: points,
    numPoints,
    id: `${numPoints}_${points[0].id}`,
  });

  const isMobile = width <= 768;

  useEffect(() => {
    if (isMobile) {
      if ("geolocation" in navigator && !watchPositionId) {
        setWatchPositionId(
          navigator.geolocation.watchPosition(
            function (position) {
              console.log("position", position.coords);
              setPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            function (error) {},
            {
              enableHighAccuracy: true,
              maximumAge: 10000,
            }
          )
        );
      } else {
        console.log("Geolocation is not available in your browser.");
      }
    }
  }, [width]);

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      if ("geolocation" in navigator && watchPositionId) {
        navigator.geolocation.clearWatch(watchPositionId);
      }
    };
  }, []);

  useEffect(() => {
    if (lightboxsWithState && lightboxsWithState.length && lightboxs.length) {
      const normals = lightboxs.filter((i) =>
        lightboxsWithState.find(
          (l) => l.id === i.id && l.device.currentState === DeviceState.NORMAL
        )
      );
      const errors = lightboxs.filter((i) =>
        lightboxsWithState.find(
          (l) => l.id === i.id && l.device.currentState === DeviceState.ERROR
        )
      );
      const lightOns = lightboxs.filter((i) =>
        lightboxsWithState.find(
          (l) => l.id === i.id && l.device.currentState === DeviceState.LIGHT_ON
        )
      );
      setLightboxsNormal(normals);
      setLightboxsError(errors);
      setLightboxsLightOn(lightOns);
    }
  }, [lightboxsWithState, lightboxs]);

  useEffect(() => {
    if (selectedLightboxId) {
      const lightbox = lightboxs.find((i) => i.id === selectedLightboxId);
      if (lightbox) {
        getPoles({ variables: { lightboxId: lightbox.id } });
        setSelectedLightbox(lightbox);
        setMapProps({
          // ...mapProps,
          zoom: ZOOM_DETAIL,
          center: {
            lat: lightbox.lat,
            lng: lightbox.lng,
          },
        });
        setSelectedLightboxId(0);
        onBoxClick(lightbox, true);
      }
    }
  }, [selectedLightboxId]);

  useEffect(() => {
    if (selectedLightbox) {
      getPoles({ variables: { lightboxId: selectedLightbox.id } });
    }
  }, [selectedLightbox]);

  useEffect(() => {
    if (mapProps.bounds) {
      const delay =
        mapProps.zoom - preMapProps.zoom > 1 ? ZOOM_TIMEOUT_OFFSET : 0;
      if (delay) {
        setTimeout(() => {
          setClusterNormal(clNormal({ ...mapProps }).map(mapCluster));
          setClusterError(clError({ ...mapProps }).map(mapCluster));
          setClusterLightOn(clLightOn({ ...mapProps }).map(mapCluster));
        }, delay);
      } else {
        setClusterNormal(clNormal({ ...mapProps }).map(mapCluster));
        setClusterError(clError({ ...mapProps }).map(mapCluster));
        setClusterLightOn(clLightOn({ ...mapProps }).map(mapCluster));
      }
    }
    setPreMapProps({ ...mapProps });
  }, [mapProps, lightboxsNormal, lightboxsError, lightboxsLightOn]);

  const handleBoxClick = (item: any) => {
    const show = mapProps.zoom >= MIN_ZOOM_DETAIL;
    if (!show) {
      setMapProps({
        zoom: ZOOM_DETAIL,
        center: {
          lat: item.lat,
          lng: item.lng,
        },
      });
      setSelectedLightbox(item);
    }
    return onBoxClick(item, show);
  };

  const handleClusterClick = ({ lat, lng }: any) => {
    setMapProps({
      center: { lat, lng },
      zoom: mapProps.zoom < MAX_ZOOM_CLUSTER ? MAX_ZOOM_CLUSTER : ZOOM_DETAIL,
    });
  };

  const handleCurrentLocationClick = () => {
    setMapProps({
      center: isMobile ? position : mapProps.center,
      zoom: MAX_ZOOM_CLUSTER,
    });
  };

  // DIRECTION FUNCTION
  // const apiIsLoaded = (map: any, maps:any) => {
  //   const directionsService = new maps.DirectionsService();
  //   const directionsRenderer = new maps.DirectionsRenderer();
  //   directionsRenderer.setMap(map);
  //   const origin = { lat: 10.841171782438195, lng: 106.72319976480085, id: "" };
  //   const destination = { lat: 10.845723883574752, lng: 106.71993819887079 };

  //   directionsService.route(
  //     {
  //       origin: origin,
  //       destination: destination,
  //       travelMode: maps.TravelMode.DRIVING
  //     },
  //     (result: any, status:any) => {
  //       if (status === maps.DirectionsStatus.OK) {
  //         directionsRenderer.setDirections(result);
  //       } else {
  //         console.error(`error fetching directions ${result}`);
  //       }
  //     }
  //   );
  // };

  return (
    <div style={{ height: "93vh", width: "100%" }}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key: "AIzaSyCyviBaJdeM3N_9DW9iDnkI5X4nNWG9cak",
          region: "VN",
          language: "vn",
        }}
        // onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
        // debounced
        // style={style}
        // layerTypes={['TrafficLayer', 'TransitLayer']}
        options={(maps) => ({
          minZoom: 3,
          maxZoom: 22,
          controlSize: 22,
          streetViewControl: false,
          scaleControl: true,
          fullscreenControl: false,
          zoomControl: true,
          keyboardShortcuts: true,
          clickableIcons: false,
          disableDefaultUI: true,
          gestureHandling: "greedy",
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_LEFT,
            mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
          },
        })}
        center={mapProps.center}
        zoom={mapProps.zoom}
        onChange={(value) => {
          setMapProps(value);
        }}
        // onChildMouseEnter={onChildMouseEnter}
        // onChildMouseLeave={onChildMouseLeave}
      >
        {isMobile ? (
          <CurrentLocation {...position} />
        ) : (
          <CurrentLocation {...mapProps.center} />
        )}
        {clusterNormal.map(
          ({ id, numPoints, lightboxs, ...markerProps }: any) =>
            numPoints === 1 ? (
              lightboxs[0].id !== selectedLightboxId && (
                <LightboxPin
                  key={lightboxs[0].id}
                  lightbox={lightboxs[0]}
                  zoom={mapProps.zoom}
                  {...markerProps}
                  handleClick={handleBoxClick}
                  state={DeviceState.NORMAL}
                />
              )
            ) : (
              <ClusterMarker
                key={id}
                numPoints={numPoints}
                {...markerProps}
                onClick={() => handleClusterClick({ ...markerProps })}
                state={DeviceState.NORMAL}
              />
            )
        )}
        {clusterError.map(
          ({ id, numPoints, lightboxs, ...markerProps }: any) =>
            numPoints === 1 ? (
              lightboxs[0].id !== selectedLightboxId && (
                <LightboxPin
                  key={lightboxs[0].id}
                  lightbox={lightboxs[0]}
                  zoom={mapProps.zoom}
                  {...markerProps}
                  handleClick={handleBoxClick}
                  state={DeviceState.ERROR}
                />
              )
            ) : (
              <ClusterMarker
                key={id}
                numPoints={numPoints}
                {...markerProps}
                onClick={() => handleClusterClick({ ...markerProps })}
                state={DeviceState.ERROR}
              />
            )
        )}
        {clusterLightOn.map(
          ({ id, numPoints, lightboxs, ...markerProps }: any) =>
            numPoints === 1 ? (
              lightboxs[0].id !== selectedLightboxId && (
                <LightboxPin
                  key={lightboxs[0].id}
                  lightbox={lightboxs[0]}
                  zoom={mapProps.zoom}
                  {...markerProps}
                  handleClick={handleBoxClick}
                  state={DeviceState.LIGHT_ON}
                />
              )
            ) : (
              <ClusterMarker
                key={id}
                numPoints={numPoints}
                {...markerProps}
                onClick={() => handleClusterClick({ ...markerProps })}
                state={DeviceState.LIGHT_ON}
              />
            )
        )}
        {selectedLightbox &&
          mapProps.zoom >= MIN_ZOOM_DETAIL &&
          poles
            .filter((p) => p.lat && p.lng)
            .map((pole) => (
              <PolePin
                key={pole.id}
                {...pole}
                pole={pole}
                lightbox={selectedLightbox}
                handleClick={onPoleClick}
              />
            ))}
        {selectedLightbox && (
          <LightboxPin
            key={selectedLightbox.id}
            lightbox={selectedLightbox}
            zoom={mapProps.zoom}
            {...selectedLightbox}
            isSelected
            handleClick={handleBoxClick}
            state={lightboxsWithState.find((l) => l.id === selectedLightbox.id).device.currentState}
          />
        )}
      </GoogleMapReact>
      <div
        onClick={handleCurrentLocationClick}
        title="Vị trí hiện tại"
        className="absolute flex items-center rounded-[50%] justify-center bottom-[32px] md:bottom-[72px] right-[6px] bg-variant-white w-[52px] h-[52px] text-neutral-d50 hover:text-neutral-d90 cursor-pointer"
      >
        <AimOutlined className="text-2xl"></AimOutlined>
      </div>
    </div>
  );
};

export default DashboardMap;
