import { DeviceState } from "../../../../graphql/lightboxs";

export function getColorCssByState(state: DeviceState) {
    let color = "";
    switch (state) {
      case DeviceState.ERROR:
        color = "text-variant-error";
        break;
      case  DeviceState.NORMAL:
        color = "text-neutral-d50";
        break;
      case  DeviceState.LIGHT_ON:
        color = "text-variant-success";
        break;
      default:
        color = "text-variant-warning";
        break;
    }
    return color;
}