import { gql } from "@apollo/client";

export const GET_POLES = gql`
  query GetPoles($lightboxId: Int!) {
    poles(lightboxId: $lightboxId) {
      id
      name
      isAlive
      onOff
      dim
      lat
      lng
    }
  }
`;

export const GET_POLE = gql`
  query GetPoles($lightboxId: Int!, $poleId: String!) {
    pole(id: $poleId) {
      id
      clientId
      name
      onOff
      dim
      ctValue
      ptValue
      isAlive
      lat
      lng
    }
    lightbox(id: $lightboxId) {
      id
      boxId
      name
    }
  }
`;

export const GET_POLE_TIMESCALE = gql`
  query PoleTimescaleData($id: String!, $clientId: String! $from: DateTime!, $to: DateTime!) {
    poleTimescaleData(id: $id, clientId: $clientId from: $from, to: $to) {
      id
      time
      dim
      ptValue
      ctValue
    }
  }
`;