import { useQuery } from "@apollo/client";
import { Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { GET_REGISTER_LOGS } from "../../../../graphql/registerLogs";
import "./index.css";
import moment from "moment";

interface IRegisterHistoryProps {
  data: any[];
}

const RegisterHistory: React.FunctionComponent<IRegisterHistoryProps> = ({
  data,
  ...props
}: IRegisterHistoryProps) => {
  const columns: ColumnsType<any> = [
    // {
    //   title: "ID",
    //   dataIndex: "key",

    //   width: 50,
    // },
    {
      title: "Tên tủ",
      dataIndex: "lightbox",
      render: (_, { lightbox }) => {
        return <span>{lightbox.name}</span>;
      },
      // width: 100,
    },
    {
      title: "Thiết bị",
      dataIndex: "clientId",
    },
    {
      title: "Thời điểm",
      dataIndex: "createdAt",
      render: (_, { createdAt }) => {
        const date = moment(createdAt).format("DD/MM/YY");
        const hour = moment(createdAt).format("HH:mm");
        const result = `${date} ${hour}`;
        return <span>{result}</span>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      render: (_, { status }) => {
        let text = "Fail";
        let color = "volcano";
        if (status === 1) {
          text = "Process";
          color = "blue";
        }
        if (status === 2) {
          text = "Done";
          color = "green";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{
        position: ["bottomRight"],
        total: data.length,
        showTotal: (total) => `Tổng ${total}`,
        defaultPageSize: 20,
      }}
    />
  );
};

export default RegisterHistory;
