import { gql } from "@apollo/client";

export const GET_ACTIVE_NOTIFICATIONS_COUNT = gql`
  query ActiveNotifications {
    unloadedNotificationsCount
  }
`;

export const GET_ACTIVE_NOTIFICATIONS = gql`
  query ActiveNotifications($paging: Paging) {
    activeNotificationsCount
    activeNotifications(paging: $paging) {
      id
      type
      title
      message
      viewed
      createdAt
      objectId
    }
  }
`;

export const MARK_NOTIFICATIONS_LOADED = gql`
  mutation MarkNotificationsLoaded {
    markNotificationsLoaded
  }
`;

export const MARK_NOTIFICATIONS_VIEWED = gql`
  mutation MarkNotificationViewed($notificationId: Int!) {
    markNotificationViewed(notificationId: $notificationId)
  }
`;

export const CLEAR_NOTIFICATIONS = gql`
  mutation ClearNotifications($ids: [Int!]!) {
    clearNotifications(ids: $ids)
  }
`;

export const GET_NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription {
    notificationChange {
      id
      title
      message
      type
      viewed
      createdAt
      objectId
    }
  }
`;
