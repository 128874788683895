import * as React from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { Checkbox, DatePicker, Select, Spin } from "antd";
import {
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { GET_POLE_TIMESCALE } from "../../../../../graphql/poles";
import { AuthContext } from "../../../../../context/AuthContext";
ChartJS.register(
  TimeScale, //Register timescale instead of category for X axis
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
  //   ...registerables
);
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
interface IDetailChartsProps {
  pole?: any;
}

const colors = ["74, 66, 205", "255, 99, 132", "83, 200, 82"];

export const getChartOptions: any = (
  title: string,
  displayLegend = true,
  zoom = true
) => ({
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      type: "time",
      display: true,
      time: {
        minUnit: "hour",
        format: "HH:mm",
        displayFormats: {
          hour: "HH:mm",
        },
      },
      title: {
        display: true,
        text: displayLegend ? "Thời gian" : "Giờ",
      },
    },
    y: {
      title: {
        display: true,
        text: displayLegend ? title : "kWh",
        font: {
          size: 16,
        },
      },
      stacked: false,
      beginAtZero: true,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    zoom: zoom
      ? {
          zoom: {
            wheel: {
              enabled: true,
              //   mode: "x",
            },
            pinch: {
              enabled: true,
            },
            mode: "xy",
          },
          pan: {
            enabled: true,
            mode: "xy",
          },
        }
      : undefined,
    title: {
      display: true,
      text: title,
      color: "#000",
    },
  },
});

enum ChartType {
  VOLTAGE,
  CURRENT,
  POWER,
}

const getChartData = (data: any[], type: ChartType) => {
  let keys = [];
  let title = "";
  let label = "";
  let fill: any = false;
  switch (type) {
    case ChartType.VOLTAGE:
      keys = ["ptValue"];
      title = "Điện áp (V)";
      break;
    case ChartType.CURRENT:
      keys = ["ctValue"];
      title = "Dòng điện (mA)";
      break;
    default:
      keys = ["dim"];
      title = "Tiết giảm (%)";
      break;
  }

  const datasets = keys.map((key, index) => {
    const d = data.map((i: any) => ({
      x: i.time,
      y: i[key],
    }));
    const result = [d[0]];
    let pre = result[0];
    let existSame = false;
    for (let k = 1; k < d.length - 1; k++) {
      const cur = d[k];
      if (pre.y !== cur.y) {
        if (existSame) {
          result.push(pre);
          existSame = false;
        }
        result.push(cur);
      } else {
        existSame = true;
      }
      pre = cur;
    }
    result.push(d[d.length - 1]);
    return {
      data: result,
      fill: fill,
      tension: 0.05,
      borderColor: `rgb(${colors[index]})`,
      backgroundColor: `rgba(${colors[index]}, 0.5)`,
    };
  });
  return { datasets, title };
};

const DetailLightCharts: React.FunctionComponent<IDetailChartsProps> = ({
  pole,
}) => {
  const dateFormat = "YYYY-MM-DD HH:mm";
  const defaultFilterRange: [moment.Moment, moment.Moment] = [
    moment().subtract(1, "days"),
    moment(),
  ];
  const [tempFilterRange, setTempFilterRange] =
    React.useState<any>(defaultFilterRange);

  const [chartType, setChartType] = React.useState<ChartType>(ChartType.POWER);

  const { userInfo } = React.useContext(AuthContext);

  const chartOptions = userInfo?.isSuperAdmin
    ? [
        { value: ChartType.POWER, label: "Tiết giảm" },
        { value: ChartType.VOLTAGE, label: "Điện áp" },
        { value: ChartType.CURRENT, label: "Dòng điện" },
      ]
    : [{ value: ChartType.POWER, label: "Tiết giảm" }];

  const defaultFilterValue = {
    dateRange: defaultFilterRange,
  };

  const [errorFilter, setErrorFilter] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState<any>(defaultFilterRange);

  const [getData, { data: allData, loading }] = useLazyQuery<any, any>(
    GET_POLE_TIMESCALE,
    {
      fetchPolicy: "cache-first",
    }
  );

  const resetFilter = () => {
    setFilterValue(defaultFilterValue);
  };

  const renderChart = React.useMemo(() => {
    if (!allData) return <></>;
    const data = allData.poleTimescaleData;
    const { datasets, title } = getChartData(data, chartType);
    const height = 160;
    return (
      <>
        <Line
          options={getChartOptions(title, true)}
          height={height}
          data={{ datasets }}
        />
      </>
    );
  }, [chartType, allData]);

  React.useEffect(() => {
    if (moment(filterValue[1]).diff(moment(filterValue[0]), "days") > 7) {
      setErrorFilter(true);
      setFilterValue(tempFilterRange);
      return;
    }
    getData({
      variables: {
        clientId: pole.clientId,
        id: pole.id,
        from: filterValue[0],
        to: filterValue[1],
      },
    });
  }, [filterValue]);

  return (
    <div className={`flex flex-col flex-auto md:min-w-[720px] px-8`}>
      <div className="flex flex-col gap-[8px] pb-4">
        <div className="flex items-center">
          <div className="w-[120px]">Loại biểu đồ:</div>
          <Select
            style={{ width: 188 }}
            placeholder="Chọn loại biểu đồ"
            // defaultValue={0}
            value={chartType}
            onChange={(t) => setChartType(t)}
            options={chartOptions}
          />
          {loading && <Spin className="px-4" tip="Đang tải" />}
        </div>
        <div className="flex items-center">
          <div className="w-[120px]">Khoảng thời gian: </div>
          <DatePicker.RangePicker
            className="h-[32px]"
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            onCalendarChange={(dates) => {
              setTempFilterRange(dates);
            }}
            onOpenChange={(open) => {
              if (!open) {
                setFilterValue(tempFilterRange);
              }
            }}
            value={filterValue}
            disabledDate={(current) => {
              return moment().add(0, "days") <= current;
            }}
            placeholder={["Từ", "Đến"]}
          />
        </div>
        {errorFilter && (
          <span className="ml-[120px] text-variant-error italic">
            Khoảng thời gian cho phép trong vòng 7 ngày
          </span>
        )}
      </div>
      <div>{renderChart}</div>
    </div>
  );
};

export default DetailLightCharts;
