export const DEFAULT_PAGE_SIZE = 15;

export type Statistic = {
  total: number;
  price: number;
  quantity?: number;
};

export type Paging = {
  count: number;
  skip?: number;
  orderBy?: [OrderBy]
}
type OrderBy = {
  field: string;
  order: Order
}
export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export function numWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const toBlob = (image: string) => {
  return `data:image/png;base64, ${image}`;
};
