import { gql } from "@apollo/client";
import { Device } from "./devices";
import { Paging } from "./common";

export type ImportItemRecord = {
  key: React.Key;
  itemName: string;
  itemSize: string;
  quantity: number;
  price: number;
  createdAt: string;
  createdBy: string;
};

export type Project = {
  id: number;
  name: string;
  quater: number;
  year: number;
};

export type RegisterLog = {
  id: number;
  clientId: string;
  imageUrl?: string;
};

export type LightBoxRecord = {
  key: React.Key;
  id: number;
  boxId: string;
  name: string;
  deviceId: string;
  location: string;
  address: string;
  connectionDNS: string;
  lat: number;
  lng: number;
  imageUrl?: string;
  device: Device;
};

export type LightBox = {
  PENumber?: string;
  clientId?: string;
  address: string;
  boxId: string;
  connectionDNS: string;
  connectionPort?: number;
  createdAt: Date;
  id: number;
  isRegistered: boolean;
  lat?: number;
  lng?: number;
  name: string;
  device: Device;
  orderNumber: number;
  project?: Project;
  projectId?: number;
  quater: number;
  registerLog?: RegisterLog;
  registeredAt?: Date;
  updatedAt: Date;
  year: number;
};

export type GetLightboxsFilterInput = {
  input: {
    projectId?: number;
    status?: number;
    text?: string;
  };
  paging?: Paging;
};

export enum DeviceState {
  ERROR = "ERROR",
  NORMAL = "NORMAL",
  LIGHT_ON = "LIGHT_ON",
  SAVING_ENERGY = "SAVING_ENERGY",
}

export const GET_LIGHTBOXS_WITH_FILTER = gql`
  query GetLightBoxs($input: FilterLightBoxInput!, $paging: Paging) {
    lightboxs(input: $input, paging: $paging) {
      id
      name
      boxId
      address
      connectionDNS
      lat
      lng
      clientId
      registerLog {
        imageUrl
      }
      device {
        id
        liveData {
          isConnected
          pm_connect
          lc_connect
          plc_connect
        }
      }
    }
  }
`;

export const GET_LIGHTBOX = gql`
  query GetLightBox($id: Int!) {
    lightbox(id: $id) {
      id
      name
      boxId
      address
      lat
      lng
      province {
        id
        displayName
      }
      district {
        id
        displayName
      }
      clientId
      device {
        id
      }
      registerLog {
        imageUrl
      }
    }
  }
`;

export const GET_LIGHTBOXS = gql`
  query GetLightBoxs($input: FilterLightBoxInput!, $paging: Paging) {
    lightboxs(input: $input, paging: $paging) {
      id
      name
      boxId
      address
      connectionDNS
      lat
      lng
      clientId
      registerLog {
        imageUrl
      }
    }
    lightboxsCount(input: $input)
  }
`;

export const GET_LIGHTBOXS_3 = gql`
  query GetLightBoxs($input: FilterLightBoxInput!, $paging: Paging) {
    lightboxs(input: $input, paging: $paging) {
      id
      name
      address
      boxId
      lat
      lng
      clientId
      isRegistered
    }
  }
`;

export const GET_LIGHTBOXS_4 = gql`
  query GetLightBoxs($input: FilterLightBoxInput!, $paging: Paging) {
    lightboxs(input: $input, paging: $paging) {
      id
      boxId
      name
      province {
        id
        displayName
      }
      district {
        id
        displayName
      }
    }
  }
`;

export const GET_LIGHTBOXS_DAHSBOARD_MAP = gql`
  query GetLightBoxsForMap($input: FilterLightBoxInput!, $paging: Paging) {
    lightboxs(input: $input, paging: $paging) {
      id
      name
      boxId
      lat
      lng
    }
  }
`;

export const GET_LIGHTBOXS_SEARCH = gql`
  query GetLightBoxs($input: FilterLightBoxInput!, $paging: Paging) {
    lightboxs(input: $input, paging: $paging) {
      id
      name
      address
      boxId
    }
  }
`;

export const GET_LIGHTBOXS_INRANGE = gql`
  query GetLightBoxsInRange($input: GetLightBoxInRangeInput!) {
    lightboxsInRange(input: $input) {
      id
      name
      boxId
    }
  }
`;

export const GET_LIGHTBOXS_STATISTIC = gql`
  query {
    lightboxsStatistic {
      id
      boxId
      name
      device {
        id
        currentState
      }
      provinceId
      districtId
    }
  }
`;

export const GET_LIGHTBOXS_INRANGE_DASHBOARD = gql`
  query GetLightBoxsInRange($input: GetLightBoxInRangeInput!) {
    lightboxsInRange(input: $input) {
      id
      name
      boxId
      lat
      lng
    }
  }
`;

export const GET_INCIDENT_LIGHTBOXS = gql`
  query LightboxsWithIncidents($paging: Paging) {
    lightboxsWithIncidents(paging: $paging) {
      id
      name
      device {
        id
        incidents {
          incidentType {
            name
          }
        }
      }
    }
  }
`;

export const RESET_MUTATION = gql`
  mutation DeviceReset($id: String!) {
    deviceReset(id: $id) {
      id
    }
  }
`;

export const NET_PROVIDER: any = {
  1: "Viettel",
  2: "Mobifone",
  3: "Vinaphone",
};

export const NET_GEN: any = {
  2: "GPRS",
  3: "3G",
  4: "4G",
  5: "5G",
};
