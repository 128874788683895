import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
export function sortByKey(array: any[], key: string) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function formatTime(time?: string, format = "HH:mm"): Dayjs | undefined {
  if (!time) return undefined;
  const t = `${time.slice(0, 2)}:${time.slice(2)}`;
  return dayjs(t, format);
}

export function formatTime1(time?: any, format = "HH:mm"): Dayjs | undefined {
  if (!time) return undefined;
  // const t =  `${time.slice(0,2)}:${time.slice(2)}`;
  return dayjs(time, format);
}

export function isBetweenTimes(
  compare_time: string,
  start_time: string,
  end_time: string,
) {
  // const now = parseInt(now_str);
  const now = parseInt(compare_time);
  const start = parseInt(start_time);
  const end = parseInt(end_time);
  if (end - start < 0) {
    return now >= start || now < end;
  } else {
    return now >= start && now < end;
  }
}

export function compareTime(str1: string, str2: string): number {
  let time1 = parseInt(str1);
  time1 = time1 < 1200 ? time1 + 2400 : time1;
  let time2 = parseInt(str2);
  time2 = time2 < 1200 ? time2 + 2400 : time2;
  if (time1 < time2) return -1;
  else if (time1 === time2) return 0;
  else return 1;
}
