import { HddFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { DeviceState } from "../../../../graphql/lightboxs";
import { ReactComponent as StreetLightOn } from "./icon/street-light-on.svg";
import { ReactComponent as StreetLightOff } from "./icon/street-light-off.svg";
import { ReactComponent as StreetLightError } from "./icon/street-light-error.svg";

interface IPolePinProps {
  id: string;
  lightbox: any;
  pole: any;
  name: string;
  handleClick: (pole: any) => void;
  // state: DeviceState;
  // zoom: number;
}

const PolePin = ({ id, lightbox, pole, handleClick }: IPolePinProps) => {
  const status = !pole.isAlive ? "Mất kết nối" :pole.onOff ? `Sáng (${pole.dim}%)` : "Tắt";
  const title = `Trụ: ${pole.name}\nThuộc tủ: ${lightbox.name} (${lightbox.boxId})\nTrạng thái: ${status}`;
  return (
    <div
      className="flex flex-col items-center justify-center relative translate-x-[-50%] translate-y-[-100%] cursor-pointer"
      onClick={() => handleClick(pole)}
    >
      <Tooltip
        title={title}
        zIndex={1}
        color="#FFFFFF"
        overlayInnerStyle={{ color: "#000", whiteSpace: "pre-line" }}
      >
        <div className="text-sm relative font-bold text-[#266300] whitespace-nowrap">
          {pole.name}
        </div>
        {!pole.isAlive ? (
          <StreetLightError />
        ) : pole.onOff ? (
          <StreetLightOn />
        ) : (
          <StreetLightOff />
        )}
      </Tooltip>
    </div>
  );
};
export default PolePin;
