import { FundFilled } from "@ant-design/icons";

const DevicePin = ({ device, onClick, unregistered }: any) => {
  return (
    <div
      className="flex flex-col items-center justify-center relative translate-x-[-50%] translate-y-[-50%] cursor-pointer"
      onClick={() => onClick(device)}
    >
      <div className="text-sm relative font-medium text-variant-error whitespace-nowrap">
        {device.id}
      </div>
      <FundFilled className={`text-2xl text-variant-error`} />
    </div>
  );
};
export default DevicePin;
