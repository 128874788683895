import { InfoCircleOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Spin,
  Switch,
  Tabs,
} from "antd";
import * as React from "react";
import { formatTime } from "../../../utils/helpers";
import "./index.css";
// import DetailCharts from "./components/charts";
import { AuthContext } from "../../../context/AuthContext";
import { GET_POLE } from "../../../graphql/poles";
import DetailLightCharts from "./components/charts";

interface IPoleDetailModalProps extends ModalProps {
  onCancel: () => void;
  showLightboxDetail: (lightbox: any, show: boolean) => void;
  lightboxId: number;
  poleId: string;
  visible: boolean;
}

const PoleDetailModal: React.FunctionComponent<IPoleDetailModalProps> = ({
  visible,
  lightboxId,
  poleId,
  onCancel,
  showLightboxDetail,
  ...props
}: IPoleDetailModalProps) => {
  // const [form1] = Form.useForm();
  const { userInfo } = React.useContext(AuthContext);
  const [form] = Form.useForm();
  const timeFormat = "HH:mm";
  const [pole, setPole] = React.useState<any>(undefined);
  const [lightbox, setLightbox] = React.useState<any>(undefined);
  const [allowSetting, setAllowSetting] = React.useState(false);
  const [isAlive, setIsAlive] = React.useState(false);
  const [isOn, setIsOn] = React.useState(false);

  const [getPole, { loading, startPolling }] = useLazyQuery<any, any>(
    GET_POLE,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted(data) {
        setPole(data.pole);
        setLightbox(data.lightbox);
        setIsAlive(!!data.pole.isAlive);
        setIsOn(!!data.pole.onOff);
        form.setFieldsValue({
          ...data.pole,
          lightboxName: `${data.lightbox.name} (${data.lightbox.boxId})`,
          // lightboxId: data.lightbox.boxId,
        });
        startPolling(10000);
      },
    }
  );

  React.useEffect(() => {
    if (visible && lightboxId && !!poleId)
      getPole({
        variables: {
          poleId,
          lightboxId,
        },
      });
  }, [visible, poleId, lightboxId]);

  const handleBoxClick = () => {
    onCancel();
    showLightboxDetail(lightbox, true);
  };

  return (
    <>
      <Modal
        className="CustomModal !w-auto "
        title={
          loading ? (
            <Spin />
          ) : pole ? (
            <div
              className={`py-4 px-6 mx-[-24px] my-[-16px] overflow-auto ${
                !isAlive
                  ? "bg-variant-error"
                  : isOn
                    ? "bg-variant-success"
                    : "bg-neutral-l70"
              }`}
            >
              Trụ {pole.name}
            </div>
          ) : (
            ""
          )
        }
        open={visible}
        destroyOnClose={true}
        maskClosable={false}
        centered
        footer={false}
        onCancel={onCancel}
      >
        <Tabs>
          <Tabs.TabPane tab="Thông tin" key="1">
            <Form
              form={form}
              name="basic"
              // labelCol={{ span: 6 }}
              wrapperCol={{ span: 24 }}
              className="grid grid-cols-4 gap-2"
              autoComplete="off"
            >
              {allowSetting && (
                <div className="flex items-center col-span-4 gap-2">
                  <Button
                    onClick={() => {
                      const d = lightbox.device.liveData;
                      form.setFieldsValue({
                        ...d,
                        allow_br1: !d.disbr1,
                        allow_br2: !d.disbr2,
                        br1_ontime: formatTime(d.br1_ontime),
                        br1_offtime: formatTime(d.br1_offtime),
                        br2_ontime: formatTime(d.br1_ontime),
                        br2_offtime: formatTime(d.br1_ontime),
                        hps_ontime: formatTime(d.hps_ontime),
                        hps_offtime: formatTime(d.hps_offtime),
                      });
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    className="bg-blue-primary text-variant-white"
                    htmlType="submit"
                  >
                    Xác nhận
                  </Button>
                </div>
              )}
              {/* <Divider className="col-span-4" /> */}

              <div className="flex items-center col-span-2">Mã trụ:</div>
              <Form.Item className="col-span-2" name="id">
                <Input className="w-full" readOnly />
              </Form.Item>
              <div className="flex items-center col-span-2">Thuộc tủ:</div>
              <Form.Item className="col-span-2" name="lightboxName">
                <Input
                  className="w-full"
                  readOnly
                  suffix={
                    <InfoCircleOutlined
                      title="Chi tiết"
                      className="text-blue-primary cursor-pointer"
                      onClick={handleBoxClick}
                    />
                  }
                />
              </Form.Item>
              {/* <div className="flex items-center col-span-2">Tên tủ:</div>
          <Form.Item className="col-span-2" name="lightboxName">
            <Input className="w-full" readOnly />
          </Form.Item> */}
              {/* <div className="flex items-end w-fit col-span-1" title="Đồng bộ">
                <Button
                  onClick={handleSyncTimePLC}
                  disabled={!allowSetting}
                  icon={<SyncOutlined />}
                >
                  <div className="hidden md:inline-block ml-1">Đồng bộ</div>
                </Button>
              </div> */}
              <Divider className="col-span-4" />

              <div className="flex items-center col-span-2">
                Trạng thái kết nối:
              </div>
              <Form.Item
                className="col-span-2"
                name="isAlive"
                valuePropName="checked"
              >
                <Switch
                  className="customSwitch w-[68px]"
                  checkedChildren="Có"
                  unCheckedChildren="Chưa"
                  disabled
                />
              </Form.Item>
              {isAlive && (
                <>
                  <div className="flex items-center col-span-2">
                    Trạng thái đèn:
                  </div>
                  <Form.Item
                    className="col-span-2"
                    name="onOff"
                    valuePropName="checked"
                  >
                    <Switch
                      className="customSwitch w-[68px]"
                      unCheckedChildren="Tắt"
                      checkedChildren="Bật"
                      disabled
                    />
                  </Form.Item>
                  <div className="flex items-center col-span-2">
                    Mức công suất (%):
                  </div>
                  <Form.Item className="col-span-2" name="dim">
                    <InputNumber
                      className="w-[78px]"
                      readOnly
                      // defaultValue={100}
                      min={0}
                      max={100}
                    />
                  </Form.Item>
                  <div className="flex items-center col-span-2">
                    Dòng điện (mA):
                  </div>
                  <Form.Item className="col-span-2" name="ctValue">
                    <InputNumber className="w-[78px]" readOnly />
                  </Form.Item>
                  <div className="flex items-center col-span-2">
                    Điện áp (V):
                  </div>
                  <Form.Item className="col-span-2" name="ptValue">
                    <InputNumber className="w-[78px]" readOnly />
                  </Form.Item>
                </>
              )}
              {/* <Divider className="col-span-4" /> */}
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Biểu đồ" key="2">
            <DetailLightCharts pole={pole} />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default PoleDetailModal;
