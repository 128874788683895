import { useMutation, useQuery } from "@apollo/client";
import { Button, Form, Input, Modal, ModalProps, Select, message } from "antd";
import * as React from "react";
import {
  UPDATE_USER,
  UpdateUserInput,
  User,
  isPasswordValid,
} from "../../../../graphql/users";
import { GET_PROJECTS } from "../../../../graphql/project";

interface IUpdateUserModalProps extends ModalProps {
  refetchList?: () => any;
  user?: User;
  onCancel: () => void;
  visible: boolean;
}

const UpdateUserModal: React.FunctionComponent<IUpdateUserModalProps> = ({
  refetchList,
  user,
  visible,
  onCancel,
  ...props
}: IUpdateUserModalProps) => {
  const [form] = Form.useForm();
  const [projects, setProjects] = React.useState([]);
  const [updateUser, { loading }] = useMutation<
    { userUpdate: User },
    UpdateUserInput
  >(UPDATE_USER);

  useQuery(GET_PROJECTS, {
    fetchPolicy: "no-cache",
    onCompleted(rs) {
      const sorted = rs.projects.sort(
        (a: any, b: any) => b.createdAt - a.createdAt
      );
      setProjects(
        sorted.map((i: any) => ({
          value: i.id,
          label: i.name,
        }))
      );
    },
  });

  React.useEffect(() => {
    if (visible && user) {
      form.setFieldsValue({
        ...user,
        projectIds: user.projects.map((i) => i.id),
      });
      // form.setFieldsValue({ phoneNumber: user.phoneNumber });
      // form.setFieldsValue({  });
      // form.setFieldsValue({ name: user.name });
      // form.setFieldsValue({ description: user.description });
      // form.setFieldsValue({ projects: user.projects.map((i) => i.id) });
    }
  }, [visible]);

  const onFinish = async (values: any) => {
    try {
      await updateUser({
        variables: {
          input: { ...values, phoneNumber: undefined },
        },
      });
      message.success("Cập nhật thành công");
      refetchList && refetchList();
      onCancel();
    } catch (error: any) {
      const errMsg = error.toString().replace("Error: ", "");
      message.error("Cập nhật thất bại " + errMsg);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal
        title="Thông tin người dùng"
        open={visible}
        centered
        footer={false}
        onCancel={onCancel}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="ID" name="id">
            <Input className="w-full" disabled />
          </Form.Item>
          <Form.Item label="Số điện thoại" name="phoneNumber">
            <Input
              placeholder="Nhập số điện thoại"
              className="w-full"
              disabled
            />
          </Form.Item>

          <Form.Item label="Quyền" name="roleId">
            <Select>
              <Select.Option value={1}>Guest</Select.Option>
              <Select.Option value={2}>Admin</Select.Option>
              <Select.Option value={3}>Superadmin</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Tên" name="name">
            <Input placeholder="Nhập tên" className="w-full" />
          </Form.Item>

          <Form.Item label="Mô tả" name="description">
            <Input placeholder="Nhập mô tả" className="w-full" />
          </Form.Item>

          <Form.Item label="Dự án" name="projectIds">
            <Select options={projects} mode="multiple">
            </Select>
          </Form.Item>

          {/* <Form.Item className="italic" label="Mật khẩu" name="password">
            <Input placeholder="Nhập mật khẩu mới" className="w-full" />
          </Form.Item>

          <Form.Item
            className="italic"
            label="Xác nhận mật khẩu"
            name="confirmPassword"
          >
            <Input placeholder="Nhập xác nhận mật khẩu" className="w-full" />
          </Form.Item> */}

          <Form.Item
            className="italic"
            label="Mật khẩu"
            name="password"
            rules={[
              () => ({
                validator(rule, value, callback) {
                  if (!value || isPasswordValid(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Mật khẩu phải bao gồm kí tự đặc biệt, in hoa, thường, số và ít nhất 8 kí tự"
                    )
                  );
                },
              }),
            ]}
          >
            <Input placeholder="Nhập mật khẩu mới" className="w-full" />
          </Form.Item>

          <Form.Item
            className="italic"
            label="Xác nhận mật khẩu"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Mật khẩu không khớp"));
                },
              }),
            ]}
          >
            <Input placeholder="Nhập xác nhận mật khẩu" className="w-full" />
          </Form.Item>

          <div className="w-full text-right">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="w-[180px]"
            >
              Cập nhật
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateUserModal;
