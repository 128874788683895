import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tabs,
  TabsProps,
  Tag,
  TimePicker,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import * as _ from "lodash";
import * as React from "react";
import { IncidentContext } from "../../../../context/IncidentContext";
import {
  DeviceState,
  GET_LIGHTBOXS_3,
  GET_LIGHTBOXS_4,
} from "../../../../graphql/lightboxs";
import { sortByKey } from "../../../../utils/helpers";
import { getColorCssByState } from "../map/helpers";
import "./index.css";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROJECTS } from "../../../../graphql/project";
import { AuthContext } from "../../../../context/AuthContext";
import { Order } from "../../../../graphql/common";
import {
  ExclamationCircleOutlined,
  SyncOutlined,
  ToolFilled,
} from "@ant-design/icons";
import * as control from "../../../../graphql/lightbox-control";
import ControlHistory from "../../../components/DetailModal/components/controlHistory";
import confirm from "antd/lib/modal/confirm";
import { ColumnFilterItem } from "antd/lib/table/interface";

interface IBulkControlModalProps {
  open?: boolean;
  handleCancel?: () => void;
}

const timeFormat = "HH:mm";

const BulkControlModal: React.FunctionComponent<IBulkControlModalProps> = ({
  open = false,
  handleCancel,
  ...props
}: IBulkControlModalProps) => {
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [form] = Form.useForm();
  const { userInfo } = React.useContext(AuthContext);
  const [projectList, setProjectList] = React.useState([]);
  const [selectedProjectId, setSelectedProjectId] = React.useState(-1);
  const [totalItems, setTotalItems] = React.useState(0);
  const [filterDistricts, setFilterDistricts] = React.useState<
    ColumnFilterItem[]
  >([]);
  const [selectedLightboxIds, setSelectedLightboxIds] = React.useState<
    React.Key[]
  >([]);

  const [bulkCtTypeMutation] = useMutation(control.LB_BULK_CT_TYPE_MUTATION);
  const [bulkPmkindMutation] = useMutation(control.LB_BULK_PMKIND_MUTATION);
  const [bulkSyncLocationMutation] = useMutation(control.LB_BULK_SYNC_LOCATION);
  const [bulkSyncTimeMutation] = useMutation(control.LB_BULK_SYNC_TIME);
  const [bulkTimeslotMutation] = useMutation(control.LB_BULK_TIMESLOT_MUTATION);

  const { loading } = useQuery<any, any>(GET_LIGHTBOXS_4, {
    // fetchPolicy: "no-cache",
    variables: {
      input: {
        projectId: selectedProjectId,
        isRegistered: true,
      },
      paging: {
        orderBy: [
          {
            field: "boxId",
            order: Order.ASC,
          },
        ],
      },
    },
    onCompleted(data) {
      const groupedDistricts: string[] = Object.keys(
        _.groupBy(data.lightboxs, (i) => i.district.displayName)
      );
      setFilterDistricts(groupedDistricts.map((i) => ({ text: i, value: i })));
      setDataSource(data.lightboxs.map((i: any) => ({ key: i.id, ...i })));
      setTotalItems(data.lightboxs.length);
      setSelectedLightboxIds([]);
    },
  });

  useQuery(GET_PROJECTS, {
    fetchPolicy: "no-cache",
    onCompleted(rs) {
      const result = userInfo?.projects.length
        ? userInfo?.projects
        : rs.projects;
      setProjectList(result.sort((a: any, b: any) => b.id - a.id));
      // setSelectedProjectId(result[0]);
    },
  });

  React.useEffect(() => {
    if (open && dataSource) {
      setTotalItems(dataSource.length);
    }
  }, [open]);

  const columns: ColumnsType<any> = [
    // {
    //   title: "",
    //   width: 20,
    // },
    {
      title: "Mã tủ",
      key: "boxId",
      dataIndex: "boxId",
    },
    {
      title: "Tên tủ",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Quận/Huyện",
      filters: filterDistricts,
      onFilter(value, record) {
        return record.district.displayName === value;
      },
      render: (_, { district }) => {
        return district.displayName;
      },
    },
    {
      title: "Tỉnh/TP",
      render: (_, { province }) => {
        return province.displayName;
      },
    },
  ];

  const handleSubmit = (v: any) => {
    let hasRequest = false;
    if (v.br1_ontime && v.br1_offtime && v.br2_ontime && v.br2_offtime) {
      hasRequest = true;
      // set time
      const br1_on = v.br1_ontime.format("HHmm");
      const br1_off = v.br1_offtime.format("HHmm");
      const br2_on = v.br2_ontime.format("HHmm");
      const br2_off = v.br2_offtime.format("HHmm");
      bulkTimeslotMutation({
        variables: {
          ids: selectedLightboxIds,
          br1_on,
          br1_off,
          br2_on,
          br2_off,
        },
      });
    }
    if (v.ct_type) {
      // set ct type
      hasRequest = true;
      bulkCtTypeMutation({
        variables: {
          ids: selectedLightboxIds,
          ctType: v.ct_type,
        },
      });
    }
    if (v.pmkind) {
      // set pmkind
      hasRequest = true;
      bulkPmkindMutation({
        variables: {
          ids: selectedLightboxIds,
          pmkind: v.pmkind,
        },
      });
    }
    if (hasRequest) {
      message.success("Gửi lệnh thành công!");
      form.resetFields();
    }
  };

  const handleReset = () => {
    form.resetFields();
  };

  const handleSyncTimeAll = () => {
    // sync time plc
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Đồng bộ thời gian cho ${selectedLightboxIds.length} tủ?`,
      onOk() {
        bulkSyncTimeMutation({ variables: { ids: selectedLightboxIds } })
          .then(() => {
            message.success("Gửi lệnh thành công!");
          })
          .catch((err) => message.error(err));
      },
      onCancel() {},
    });
    // bulkSyncTimeMutation({ variables: { ids: selectedLightboxIds } });
  };

  const handleSyncLocationAll = () => {
    // sync location
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Đồng bộ tọa độ cho ${selectedLightboxIds.length} tủ?`,
      onOk() {
        bulkSyncLocationMutation({
          variables: {
            ids: selectedLightboxIds,
            force: true,
          },
        })
          .then(() => {
            message.success("Gửi lệnh thành công!");
          })
          .catch((err) => message.error(err));
      },
      onCancel() {},
    });
  };

  return (
    <Modal
      className="BulkControlModal md:px-24"
      title={<span className="text-blue-primary">Điều khiển tủ hàng loạt</span>}
      destroyOnClose={true}
      open={open}
      centered
      footer={false}
      onCancel={handleCancel}
    >
      <Tabs>
        <Tabs.TabPane tab="Điều khiển" key="1">
          <div className="flex overflow-auto items-center gap-2">
            <span className="whitespace-nowrap">Dự án:</span>
            <Select
              className="w-full"
              // size="large"
              placeholder="Chọn dự án"
              value={selectedProjectId < 0 ? null : selectedProjectId}
              onChange={(value) => {
                setSelectedProjectId(value);
              }}
            >
              {projectList.map((p: any) => (
                <Select.Option key={p.id} value={p.id}>
                  {p.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Table
            // onRow={contextMenuStyle ? onRowHandle : undefined}
            loading={loading}
            scroll={{ y: 400 }}
            className="overflow-x-auto pt-4"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys: React.Key[]) => {
                setSelectedLightboxIds(selectedRowKeys);
              },
            }}
            onChange={(_, filters) => {
              if (filters[2]) {
                setTotalItems(
                  dataSource.filter(
                    (i) => filters[2]?.some((t) => t === i.district.displayName)
                  ).length
                );
              } else {
                setTotalItems(dataSource.length);
              }
            }}
            bordered
          />
          <div className="flex gap-1 py-4">
            <span>Tủ được chọn: </span>
            <span className="font-bold">
              {selectedLightboxIds.length}/{totalItems}
            </span>
          </div>
          <Form
            form={form}
            name="basic"
            wrapperCol={{ span: 24 }}
            className="grid grid-cols-6 gap-2 max-w-[720px]"
            autoComplete="off"
            onFinish={handleSubmit}
          >
            <div className="flex items-center col-span-2 gap-1">
              <ToolFilled className="text-xs mb-2 text-variant-warning" />
              Thời gian mở Nhánh 1:
            </div>
            <Form.Item className="col-span-2" label="Từ" name="br1_ontime">
              <TimePicker
                format={timeFormat}
                disabled={!selectedLightboxIds.length}
                placeholder="Chọn t.gian"
              />
            </Form.Item>
            <Form.Item className="col-span-2" label="Đến" name="br1_offtime">
              <TimePicker
                format={timeFormat}
                disabled={!selectedLightboxIds.length}
                placeholder="Chọn t.gian"
              />
            </Form.Item>
            <div className="flex items-center col-span-2 gap-1">
              <ToolFilled className="text-xs mb-2 text-variant-warning" />
              Thời gian mở Nhánh 2:
            </div>
            <Form.Item className="col-span-2" label="Từ" name="br2_ontime">
              <TimePicker
                format={timeFormat}
                disabled={!selectedLightboxIds.length}
                placeholder="Chọn t.gian"
              />
            </Form.Item>
            <Form.Item className="col-span-2" label="Đến" name="br2_offtime">
              <TimePicker
                format={timeFormat}
                disabled={!selectedLightboxIds.length}
                placeholder="Chọn t.gian"
              />
            </Form.Item>
            <div className="flex items-center col-span-2 gap-1">
              <ToolFilled className="text-xs mb-2 text-variant-warning" />
              Đồng bộ thời gian:
            </div>
            <Button
              title="Đồng bộ thời gian cho tủ"
              disabled={!selectedLightboxIds.length}
              icon={<SyncOutlined className="text-neutral-d50" />}
              className="w-[164px] col-span-4"
              onClick={handleSyncTimeAll}
            ></Button>
            <div className="flex items-center col-span-2 gap-1">
              <ToolFilled className="text-xs mb-2 text-variant-warning" />
              Đồng bộ vị trí:
            </div>
            <Button
              title="Đồng bộ tọa độ cho tủ"
              disabled={!selectedLightboxIds.length}
              icon={<SyncOutlined className="text-neutral-d50" />}
              className="w-[164px] col-span-4"
              onClick={handleSyncLocationAll}
            ></Button>
            <div className="flex items-center col-span-2 gap-1">
              <ToolFilled className="text-xs mb-2 text-variant-warning" />
              Loại đồng hồ PM:
            </div>
            <Form.Item className="col-span-4 w-[164px]" name="pmkind">
              {/* <Input className="w-full" readOnly /> */}
              <Select
                // style={{ width: 272 }}
                placeholder="Chọn loại đồng hồ"
                allowClear
                // onChange={handleChange}
                options={[
                  { value: 1, label: "1" },
                  { value: 2, label: "2" },
                ]}
                disabled={!selectedLightboxIds.length}
              />
            </Form.Item>
            <div className="flex items-center col-span-2 gap-1">
              <ToolFilled className="text-xs mb-2 text-variant-warning" />
              Loại công suất tủ:
            </div>
            <Form.Item className="col-span-2 w-[164px]" name="ct_type">
              {/* <Input className="w-full" readOnly /> */}
              <Select
                // style={{ width: 272 }}
                placeholder="Chọn công suất tủ"
                allowClear
                // onChange={handleChange}
                options={[
                  // { value: 0, label: "Không xác định" },
                  { value: 1, label: "50A" },
                  { value: 2, label: "100A" },
                ]}
                disabled={!selectedLightboxIds.length}
              />
            </Form.Item>
            <div className="col-span-4 flex gap-2">
              <Button type="default" onClick={handleCancel}>
                Hủy
              </Button>
              <Button type="default" onClick={handleReset}>
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!selectedLightboxIds.length}
              >
                Xác nhận
              </Button>
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Lịch sử điều khiển" key="2">
          <ControlHistory isBulkControl={true} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default BulkControlModal;
